import React, { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Grid,
  Paper,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useEquipment } from "../app/hooks/useEquipment";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Goback } from "./Goback";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUserRole } from "../app/hooks/useUserRole";
import { SCOPE } from "../../constants";

export const WalkdownSchedules = () => {
  const { isAdmin, isUserRoleLoading, isWalkdownCoordinator } = useUserRole();
  const { loading, getWalkDownUserWithToken, getWalkDownMasterToken } =
    useEquipment();
  const { instance, accounts } = useMsal();
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [walkdownUser, setWalkUser] = useState([]);
  useEffect(() => {
    getWalkdownSchedules();
  }, []);

  const acquireAccessToken = useCallback(async () => {
    if (accounts.length === 0) {
      throw new Error("User is not signed in");
    }
    const request = {
      scopes: [SCOPE],
      account: accounts[0],
    };

    const authResult = await instance.acquireTokenSilent(request);

    return authResult.accessToken;
  }, [accounts, instance]);

  async function getWalkdownSchedules() {
    const schedules = await getWalkDownMasterToken(); // Fetch the schedules from the API
    setSchedules(schedules);
  }
  function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }

  const handleAddScheduleAndClose = async () => {
    try {
      // Call handleAddSchedule function to save the schedule
      await handleAddSchedule();

      // Close the dialog after saving the schedule
      setPopupOpen(false);
    } catch (error) {
      // Handle any errors that may occur during the API call
      console.error("Error adding a new schedule:", error);
      setSnackbarOpen(true);
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarVariant("error");
    }
  };
  const handlePopupOpen = () => {
    setPopupOpen(true);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewScheduleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };
  useEffect(() => {
    getWalkDownUser();
  }, []);
  async function getWalkDownUser() {
    const walkdownuser = await getWalkDownUserWithToken();
    const transformedOptions = walkdownuser.map((item) => ({
      value: item.userPk,
      label: item.email,
    }));

    transformedOptions.unshift({
      value: 0,
      label: "All",
    });

    setWalkUser(transformedOptions);
  }
  const [newScheduleData, setNewScheduleData] = useState({
    selectedValue: "",
    plannedStartDate: "",
    plannedCompletionDate: "",
    // Add other fields here
  });
  const handleAddSchedule = async () => {
    try {
      const accessToken = await acquireAccessToken();
      // Validate the new schedule data, e.g., check if required fields are filled
      if (
        newScheduleData.selectedValue === "" ||
        !newScheduleData.plannedStartDate ||
        !newScheduleData.plannedCompletionDate
      ) {
        // If any required field is missing, show an error snackbar
        setSnackbarOpen(true);
        setSnackbarMessage("Please fill all the required fields.");
        setSnackbarVariant("error");
        return;
      }
      const apiUrl = `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/CreateScheduleMaster`;

      // Create the payload to send to the API
      const payload = {
        plannedStartDate: newScheduleData.plannedStartDate,
        userId: newScheduleData.selectedValue,
        plannedCompletionDate: newScheduleData.plannedCompletionDate,
      };

      // Send a POST request to the API endpoint
      await fetch(apiUrl, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(payload),
      }).then((response) => {
        if (response.ok) {
          getWalkdownSchedules();
          setSnackbarVariant("success");
          setSnackbarMessage("Schedule Created!");
          setSnackbarOpen(true);
        } else {
          // Handle the API error
          console.error("Failed to post equipment.");
        }
      });
    } catch (error) {
      // Handle any errors that may occur during the API call
      console.error("Error adding a new schedule:", error);
      setSnackbarOpen(true);
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarVariant("error");
    }
  };

  const onEquipmentTagClick = (value) => {
    navigate(`/WalkdownScheduleEquipment/${value}`);
  };
  const handleDeleteSchedule = async (scheduleId) => {
    try {
      const accessToken = await acquireAccessToken();
      // Make API call to delete the schedule with the given ID
      const apiUrl = `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/DeleteScheduleMaster/?scheduleId=${scheduleId}`;

      // Send a DELETE request to the API endpoint
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      });

      if (response.ok) {
        // Schedule deleted successfully, update the schedules list
        getWalkdownSchedules();
        setSnackbarVariant("success");
        setSnackbarMessage("Schedule deleted successfully!");
        setSnackbarOpen(true);
      } else {
        // Handle the API error if the schedule could not be deleted
        console.error("Failed to delete schedule.");
      }
    } catch (error) {
      // Handle any errors that may occur during the API call
      console.error("Error deleting schedule:", error);
      setSnackbarOpen(true);
      setSnackbarMessage(
        "An error occurred while deleting. Please try again later."
      );
      setSnackbarVariant("error");
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getUserNameLabel = (schedule) => {
    if (schedule.user) {
      return schedule.user.email;
    }
    if (schedule.userFk === 0) {
      return "All";
    }
  };

  return (
    <div>
      <Goback></Goback>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="div" gutterBottom>
              Walkdown Schedules
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 2,
              }}
            >
              <Button
                variant="contained"
                component="span"
                id="newuser"
                onClick={handlePopupOpen}
                startIcon={<Add />}
                disabled={
                  isLoading ||
                  isUserRoleLoading ||
                  (!isAdmin && !isWalkdownCoordinator)
                }
              >
                Add New Schedule
              </Button>
            </Box>
          </Box>
          <Grid container justifyContent="center">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 400,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ overflow: "auto" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "table",
                      tableLayout: "fixed",
                    }}
                  >
                    <Table size="small" sx={{ minWidth: 600 }}>
                      <TableHead>
                        <TableRow
                          sx={{
                            "& th": {
                              color: "black", // Set color to black
                              fontWeight: "bold", // Set font weight to bold
                            },
                          }}
                          hover={true}
                        >
                          <TableCell>Schedule ID</TableCell>
                          <TableCell>Planned Start Date</TableCell>
                          <TableCell>Planned Completion Date</TableCell>
                          <TableCell>User</TableCell>
                          <TableCell>Modified Date</TableCell>
                          <TableCell>Action</TableCell>
                          <TableCell>#</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {schedules.map((schedule) => (
                          <TableRow key={schedule.walkdownScheduleMasterPk}>
                            <TableCell>
                              {schedule.walkdownScheduleMasterPk}
                            </TableCell>
                            <TableCell>
                              {formatDate(schedule.plannedStartDate)}
                            </TableCell>
                            <TableCell>
                              {formatDate(schedule.plannedCompletionDate)}
                            </TableCell>
                            <TableCell>{getUserNameLabel(schedule)}</TableCell>
                            <TableCell>
                              {formatDate(schedule.scheduleModifiedDate)}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() =>
                                  onEquipmentTagClick(
                                    schedule.walkdownScheduleMasterPk
                                  )
                                }
                                sx={{ color: green[500] }}
                                aria-label="menu"
                              >
                                <Add />
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "14px" }}
                                >
                                  View/Manage Tags
                                </Typography>
                              </IconButton>
                            </TableCell>

                            <TableCell>
                              <Tooltip title="Delete">
                                <span>
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteSchedule(
                                        schedule.walkdownScheduleMasterPk
                                      )
                                    }
                                    sx={{ color: "red" }}
                                    disabled={
                                      isUserRoleLoading ||
                                      (!isAdmin && !isWalkdownCoordinator)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              )}
            </Paper>
          </Grid>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        color={snackbarVariant}
      />

      <Dialog open={isPopupOpen} onClose={handlePopupClose}>
        <DialogTitle>Add New Schedule</DialogTitle>
        <DialogContent>
          <InputLabel id="user-select-label">Select User</InputLabel>
          <Select
            labelId="user-select-label"
            label="Select User"
            fullWidth
            name="selectedValue" // Make sure the name matches your newScheduleData field
            menuportaltarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              control: (base, { selectProps }) => ({
                ...base,
                width: selectProps.value ? "200px" : "100%", // Set the width to 100% when empty, otherwise use a fixed width (e.g., 200px)
              }),
            }}
            value={newScheduleData.selectedValue} // Make sure the value matches your newScheduleData field
            onChange={handleInputChange}
          >
            {walkdownUser.map((user) => (
              <MenuItem key={user.value} value={user.value}>
                {user.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            autoFocus
            label="Planned Start Date"
            type="date"
            name="plannedStartDate"
            value={newScheduleData.plannedStartDate}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <TextField
            label="Planned Completion Date"
            type="date"
            name="plannedCompletionDate"
            value={newScheduleData.plannedCompletionDate}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAddSchedule}>
            Save
          </Button>
          <Button variant="contained" onClick={handleAddScheduleAndClose}>
            Save & Close
          </Button>
          <Button onClick={handlePopupClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
