import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const Goback = () => {
  const history = useNavigate();

  const handleGoBack = () => {
    history(-1); // Go back to the previous component
  };

  return (
    <Button
      variant="contained"
      component="span"
      sx={{ marginBottom: 2 }}
      id="newuser"
      startIcon={<ArrowBackIcon />}
      onClick={handleGoBack}
    >
      Go Back
    </Button>
  );
};