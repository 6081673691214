import { memo } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Container, CssBaseline, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Dashboard } from "../Dashboard/Dashboard";
import { AppBar } from "../Dashboard/AppBar";
import { Drawer } from "../Dashboard/Drawer";
import { Equipments } from "../Dashboard/equipments";
import { PowerBI } from "../Dashboard/PowerBI";
import { Characteristics } from "../Dashboard/Characteristics";
import { Walkdownusers } from "../Dashboard/Walkdownusers";
import { WalkdownSchedules } from "../Dashboard/WalkdownSchedules";
import { WalkdownScheduleEquipment } from "../Dashboard/WalkdownScheduleEquipment";
import { MaterialsManagement } from "../Dashboard/MaterialsManagement";
import { useUserRole } from "../app/hooks/useUserRole";

const mdTheme = createTheme();

export const App = memo(() => {
  const { isMMAnalyst, isAdmin } = useUserRole();
  const isMMBtnEnabled = isMMAnalyst || isAdmin;
  const navigate = useLocation();
  const isDrawerShown = navigate.pathname !== "/MaterialsManagement";
  return (
    <ThemeProvider theme={mdTheme}>
      <Container
        maxWidth="false"
        disableGutters
        style={{ padding: 0, margin: 0, height: "100vh" }}
      >
        <CssBaseline />
        <AuthenticatedTemplate>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <AppBar />
            <Box sx={{ height: "auto", display: "flex" }}>
              {isDrawerShown && (
                <Drawer
                  sx={{
                    width: "auto",
                    flexShrink: 0,
                    whiteSpace: "nowrap",
                  }}
                  variant="permanent"
                  open
                  isMMBtnEnabled={isMMBtnEnabled}
                  key={isMMBtnEnabled}
                />
              )}
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  paddingTop: "20px",
                  overflow: "hidden",
                }}
              >
                <Container maxWidth="false" sx={{ marginBottom: "30px" }}>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/Equipments" element={<Equipments />} />
                    <Route path="/Walkdownusers" element={<Walkdownusers />} />
                    <Route
                      path="/WalkdownSchedules"
                      element={<WalkdownSchedules />}
                    />
                    {isMMBtnEnabled && (
                      <Route
                        path="/MaterialsManagement"
                        element={<MaterialsManagement />}
                      />
                    )}
                    <Route
                      path="/Characteristics/:id"
                      element={<Characteristics />}
                    />
                    <Route path="/PowerBI" element={<PowerBI />} />
                    <Route
                      path="/WalkdownScheduleEquipment/:scheduleId"
                      element={<WalkdownScheduleEquipment />}
                    />
                  </Routes>
                </Container>
              </Box>
            </Box>
          </Box>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate />
      </Container>
    </ThemeProvider>
  );
});
