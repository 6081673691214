import { useMemo, useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import { utils, writeFile } from "xlsx";
import {
  materialsManagementColumns,
  excelFileConfig,
} from "../../app/data/materialsManagementColumns";
import { useMaterialsManagementData } from "../app/hooks/useMaterialsManagementData";

function CustomToolbar(props) {
  return (
    <GridToolbarContainer
      {...props}
      style={{ justifyContent: "space-between" }}
    >
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton />
      </div>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem />
      <ExportToExcelMenuItem />
    </GridToolbarExportContainer>
  );
}

function handleExport(apiRef) {
  const data = getExcelData(apiRef);

  const excelRows = data.map((row) => {
    const mRow = {};
    for (const key of excelFileConfig.keys) {
      mRow[key] = row[key];
    }
    return mRow;
  });

  const worksheet = utils.json_to_sheet(excelRows);
  utils.sheet_add_aoa(worksheet, [[...excelFileConfig.columnNames]], {
    origin: "A1",
  });

  const workbook = utils.book_new();
  worksheet["!cols"] = data.map(() => ({ width: 25 }));
  utils.book_append_sheet(workbook, worksheet, excelFileConfig.sheetName);
  writeFile(workbook, excelFileConfig.fileName, { compression: true });
}

function getExcelData(apiRef) {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  return data;
}

function ExportToExcelMenuItem(props) {
  const apiRef = useGridApiContext();
  const { hideMenu } = props;
  return (
    <MenuItem
      onClick={() => {
        handleExport(apiRef);
        hideMenu?.();
      }}
    >
      Download as Excel
    </MenuItem>
  );
}

export const MaterialsManagement = () => {
  const [mmData, setMMdata] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { loading, getMaterialsManagementData } = useMaterialsManagementData();
  const [recordsCount, setRecordsCount] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);

  const columns = useMemo(
    () =>
      materialsManagementColumns.map((col) =>
        col.field === "purchase_order_number_line"
          ? { ...col, filterable: false }
          : col
      ),
    []
  );

  useEffect(() => {
    getMaterialsManagementData(recordsCount, pageNumber).then((res) => {
      const formattedData = res?.allData.map((item) => ({
        ...item,
        id: item.materialsManagementPk,
      }));
      setMMdata(formattedData);
      if (res?.totalCount) {
        setTotalCount(res.totalCount);
      }
    });
  }, [recordsCount, pageNumber]);

  const handlePaginationModelChange = (data) => {
    const { page, pageSize } = data;
    const pageNumber = page + 1; // index starts from 0
    setPageNumber(pageNumber);
    setRecordsCount(pageSize);
  };

  return (
    <>
      <div style={{ fontSize: "20px", margin: "20px 0" }}>
        Materials Management
      </div>
      <div
        style={{
          height: "650px",
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        <DataGrid
          key={mmData}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            pagination: {
              paginationModel: { pageSize: recordsCount, page: pageNumber - 1 }, // here why we minues 1, because page 0 is page 1 in table.
            },
          }}
          paginationMode="server"
          columns={columns}
          rows={mmData}
          slots={{ toolbar: CustomToolbar }}
          loading={loading}
          rowCount={totalCount || 0}
          onPaginationModelChange={handlePaginationModelChange}
        />
      </div>
    </>
  );
};
