import { useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { VirtualizedAutocomplete } from "./VirtualizedAutocomplete";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useParams, useNavigate } from "react-router-dom";
import { blue } from "@mui/material/colors";
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  Cancel,
} from "@mui/icons-material";
import {
  CardContent,
  CircularProgress,
  DialogTitle,
  Snackbar,
  TextField,
  Box,
  Tab,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Paper,
  IconButton,
  Typography,
  Stack,
  Modal,
  Button,
  Tooltip,
} from "@mui/material";
import styled from "@emotion/styled";
import Title from "./Title";
import { Eportal } from "./Eportal";
import { useEquipment } from "../app/hooks/useEquipment";
import { ImageGallery } from "../ImageGallery";
import { useUserRole } from "../app/hooks/useUserRole";
import {
  USER_ROLES,
  ATRIBUTE_STATUS,
  MANUFACTURER_STATUS,
} from "../../constants";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const modalTextFieldStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "15px",
  alignItems: "center",
};

const CustomTextField = styled(TextField)({
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "84%",
    left: "-6%",
  },
});

export const Characteristics = () => {
  const search = useParams();
  const navigate = useNavigate();
  const { isAllowed, isMMAnalyst, isWalkdownCoordinator, userRole } =
    useUserRole();
  const Id = new URLSearchParams(search).get("id");
  const [
    generalCharacteristicsInitialState,
    setGeneralCharacteristicsInitialState,
  ] = useState([]);
  const [generalCharacteristicsState, setGeneralCharacteristicsState] =
    useState([]);
  const [
    specificCharacteristicsInitialState,
    setSpecificCharacteristicsInitialState,
  ] = useState([]);
  const [specificCharacteristicsState, setSpecificCharacteristicsState] =
    useState([]);
  const [equipmentImageState, setEquipmentImageState] = useState([]);
  const [selectAllStatus, setSelectAllStatus] = useState(null);
  const [value, setValue] = useState("1");
  const [isGCEditModeEnabled, setIsGCEditModeEnabled] = useState(false); // GS stands for General Characteristics
  const [isCSCEditModeEnabled, setIsCSCEditModeEnabled] = useState(false); // CSC stands for Class-Spesific Characteristics
  const [isPicturesEditEnabled, setIsPicturesEditEnabled] = useState(false);
  const [options, setOptions] = useState([]);
  const [planneroptions, setPlannerOptions] = useState([]);
  const [statusoptions, setStatusoptions] = useState([]);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState(null);
  const [selectedplanneroptions, setSelectedplanneroptions] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [DCAMStatus, setDCAMStatus] = useState("");
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [allManufacturersList, setAllManufacturersList] = useState([]);
  const [isAddNewManufacturerModalOpen, setIsAddNewManufacturerModalOpen] =
    useState(false);
  const [newManufacturerState, setNewManufacturerState] = useState({
    equipmentManufacturerPk: "",
    manufacturerNameRus: "",
    manufacturerWebSiteLink: "",
  });
  const [isManufacturerExists, setIsManufacturerExists] = useState(false);
  const [isManufacturerNameRusExists, setIsManufacturerNameRusExists] =
    useState(false);
  const [isManufacturerWebSiteLinkExists, setIsManufacturerWebSiteLinkExists] =
    useState(false);
  const [removedImageIds, setRemovedImageIds] = useState([]);

  const {
    loading,
    isCharacteristicsLoading,
    isClassSpecificLoading,
    getGeneralCharcteristicsWithToken,
    getEquipmentImage,
    deleteEquipmentImage,
    getSpecificCharcteristicsWithToken,
    getDCAMStatus,
    getCostCenterOptions,
    getPlannerGroupOptions,
    updateCharacteristics,
    getAllEquipmentManufacturers,
    addNewManufacturer,
  } = useEquipment();

  useEffect(() => {
    getGeneralCharacteristics(Id);
    getClassSpecificCharacteristics(Id);
    getEquipmentImages(Id);
  }, []);

  useEffect(() => {
    const statusString = "DN, DM, AV";
    const statusArray = statusString.split(",").map((item) => item.trim());

    const transformedOptions = statusArray.map((item) => ({
      value: item,
      label: item,
    }));

    setStatusoptions(transformedOptions);

    const loadDCAMStatus = async () => {
      try {
        const data = await getDCAMStatus();

        const transformedOptions = data.map((item) => ({
          value: item.value,
          label: item.label,
        }));

        setDCAMStatus(transformedOptions);
      } catch (error) {
        console.error(error);
      }
    };
    loadDCAMStatus();
  }, []);

  useEffect(() => {
    const loadCostCenterOptions = async () => {
      try {
        const data = await getCostCenterOptions();

        const transformedOptions = data.map((item) => ({
          value: item.value,
          label: item.label,
        }));

        setOptions(transformedOptions);
      } catch (error) {
        console.error(error);
      }
    };
    loadCostCenterOptions();
  }, []);

  useEffect(() => {
    const loadPlannerGroupOptions = async () => {
      try {
        const data = await getPlannerGroupOptions();

        const transformedOptions = data.map((item) => ({
          value: item.value,
          label: item.label,
        }));

        setPlannerOptions(transformedOptions);
      } catch (error) {
        console.error(error);
      }
    };
    loadPlannerGroupOptions();
  }, []);

  const loadAllManufacturers = async () => {
    try {
      const data = await getAllEquipmentManufacturers();

      const transformedOptions = data.map((item) => ({
        value: item.manufacturerNameEng,
        label: item.manufacturerNameEng,
      }));
      setAllManufacturersList(data);
      setManufacturerOptions(transformedOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadAllManufacturers();
  }, []);

  const handleManufacturerChange = (_event, selectedOption) => {
    const updateManufacturer = (row) => {
      if (row.characteristicName === "MANUFACTURER") {
        return {
          ...row,
          characteristicsValue: selectedOption?.value,
        };
      }
      return row;
    };
    const generalUpdatedState =
      generalCharacteristicsState.map(updateManufacturer);
    setGeneralCharacteristicsState(generalUpdatedState);
  };

  const handleGeneralStatuses = (selectedOption) => {
    setSelectAllStatus(selectedOption);
    const updatedCharacteristics = generalCharacteristicsState.map((char) => ({
      ...char,
      status: selectedOption ? selectedOption.label : null,
    }));
    setGeneralCharacteristicsState(updatedCharacteristics);
  };

  const handleClassSpecificStatuses = (selectedOption) => {
    setSelectAllStatus(selectedOption);
    const updatedCharacteristics = specificCharacteristicsState.map((char) => ({
      ...char,
      status: selectedOption ? selectedOption.label : null,
    }));
    setSpecificCharacteristicsState(updatedCharacteristics);
  };

  const handleGCEditMode = () => {
    setIsGCEditModeEnabled(true);
  };

  const handleCSCEditMode = () => {
    setIsCSCEditModeEnabled(true);
  };

  async function getGeneralCharacteristics(equipmentId) {
    try {
      const equipment = await getGeneralCharcteristicsWithToken(equipmentId);
      setGeneralCharacteristicsInitialState(equipment);
      setGeneralCharacteristicsState(equipment);
    } catch (error) {
      console.error(error);
    }
  }

  async function getClassSpecificCharacteristics(equipmentId) {
    try {
      const equipment = await getSpecificCharcteristicsWithToken(equipmentId);
      setSpecificCharacteristicsInitialState(equipment);
      setSpecificCharacteristicsState(equipment);
    } catch (error) {
      console.error(error);
    }
  }

  async function getEquipmentImages(equipmentId) {
    try {
      const images = await getEquipmentImage([equipmentId]);
      if (images) {
        setEquipmentImageState(() => images[0]?.equipmentImage);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getStatusValue = (statusValue) => {
    const selectedOption = DCAMStatus.find(
      (option) => option.label === statusValue
    );
    return selectedOption ? selectedOption.value : "";
  };

  const handleInputChange = (event, index) => {
    const updatedState = [...generalCharacteristicsState];
    updatedState[index] = {
      ...updatedState[index],
      characteristicsValue: event.target.value,
    };

    setGeneralCharacteristicsState(updatedState);
  };

  const handleInputChangeGeneral = (event, index) => {
    const updatedState = [...specificCharacteristicsState];
    updatedState[index] = {
      ...updatedState[index],
      characteristicsValue: event.target.value,
    };

    setSpecificCharacteristicsState(updatedState);
  };

  const handleCostCenterChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    const updateCostCenter = (row) => {
      if (row.characteristicName === "COST_CENTER") {
        return {
          ...row,
          characteristicsValue: selectedOption.value,
        };
      }
      return row;
    };
    const generalUpdatedState =
      generalCharacteristicsState.map(updateCostCenter);
    const classSpecificUpdatedState =
      specificCharacteristicsState.map(updateCostCenter);

    setGeneralCharacteristicsState(generalUpdatedState);
    setSpecificCharacteristicsState(classSpecificUpdatedState);
  };

  const handleChangePlanner = (selectedOption) => {
    setSelectedplanneroptions(selectedOption);
    const updatedState = generalCharacteristicsState.map((row) => {
      if (row.characteristicName === "PLANNER_GROUP") {
        return {
          ...row,
          characteristicsValue: selectedOption.value,
        };
      }
      return row;
    });

    setGeneralCharacteristicsState(updatedState);
  };

  const handleChangeStatus = (selectedOption) => {
    setSelectedStatusOptions(selectedOption);
    const updatedState = generalCharacteristicsState.map((row) => {
      if (row.characteristicName === "STATUS") {
        return {
          ...row,
          characteristicsValue: selectedOption.value,
        };
      }
      return row;
    });

    setGeneralCharacteristicsState(updatedState);
  };

  const handleStatus = (selectedOption, index) => {
    setGeneralCharacteristicsState(
      Object.values({
        ...generalCharacteristicsState,
        ...(generalCharacteristicsState[index].status !==
          selectedOption.label && {
          [index]: {
            ...generalCharacteristicsState[index],
            status: selectedOption.label,
          },
        }),
      })
    );
  };

  const handleStatusGeneral = (selectedOption, index) => {
    setSpecificCharacteristicsState(
      Object.values({
        ...specificCharacteristicsState,
        ...(specificCharacteristicsState[index].status !==
          selectedOption.label && {
          [index]: {
            ...specificCharacteristicsState[index],
            status: selectedOption.label,
          },
        }),
      })
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSpecificCharacteristicUpdate = async () => {
    const params = getUpdatedState(
      specificCharacteristicsInitialState,
      specificCharacteristicsState
    );
    if (!params.length) {
      setIsCSCEditModeEnabled(false);
      return;
    }
    setLoading(true);
    await updateCharacteristics(params)
      .then(() => {
        setSnackbarMessage("Data updated successfully!");
        setSnackbarOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update data.");
        setSnackbarOpen(true);
        console.error(error);
      })
      .finally(() => {
        setIsCSCEditModeEnabled(false);
        getGeneralCharacteristics(Id);
        getClassSpecificCharacteristics(Id);
      });
  };

  const handleBack = () => {
    const url = localStorage.getItem("equipmentUrl");
    if (url.includes("param3=back")) {
      navigate(url);
    } else {
      // Add the param3=back to the URL
      navigate(`${url}${url.includes("?") ? "&" : "?"}param3=back`);
    }
  };

  const getUpdatedState = (initialData, updatedData) => {
    const newParams = [];
    initialData.forEach((objA, index) => {
      const objB = updatedData[index];

      for (const key in objA) {
        if (objA[key] !== objB[key]) {
          newParams.push({
            ...objB,
            status: getStatusValue(objB.status),
          });
        }
      }
    });
    return newParams;
  };

  const handleGeneralCharacteristicUpdate = async () => {
    const params = getUpdatedState(
      generalCharacteristicsInitialState,
      generalCharacteristicsState
    );
    if (!params.length) {
      setIsGCEditModeEnabled(false);
      return;
    }
    setLoading(true);
    await updateCharacteristics(params)
      .then(() => {
        setSnackbarMessage("Data updated successfully!");
        setSnackbarOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update data.");
        setSnackbarOpen(true);
        console.error(error);
      })
      .finally(() => {
        setIsGCEditModeEnabled(false);
        getClassSpecificCharacteristics(Id);
        getGeneralCharacteristics(Id);
      });
  };

  const getStatusOptionsByUserRole = () => {
    switch (userRole) {
      case USER_ROLES.DATA_TEAM:
        return DCAMStatus.filter(
          (option) => option.label === ATRIBUTE_STATUS.DATA_SCRAPPING_COMPLETED
        );
      case USER_ROLES.SME:
        return DCAMStatus.filter(
          (option) =>
            option.label === ATRIBUTE_STATUS.DISCIPLINE_SME_REVIEW_COMPLETED ||
            option.label === ATRIBUTE_STATUS.ACD_COMPLETED ||
            option.label === ATRIBUTE_STATUS.IN_SCOPE_OF_DATA_SCRAPPING ||
            option.label === ATRIBUTE_STATUS.UNCONFIRMED ||
            option.label === ATRIBUTE_STATUS.DATA_SCRAPPING_COMPLETED
        );
      case USER_ROLES.ENRICHMENT_TEAM:
        return DCAMStatus.filter(
          (option) =>
            option.label === ATRIBUTE_STATUS.ENRICHMENT_COMPLETED ||
            option.label === ATRIBUTE_STATUS.IN_SCOPE_OF_DATA_SCRAPPING ||
            option.label === ATRIBUTE_STATUS.UNCONFIRMED
        );
      default:
        return DCAMStatus;
    }
  };

  const handleCancelGCEdit = () => {
    setIsGCEditModeEnabled(false);
    getGeneralCharacteristics(Id);
    setSelectAllStatus(null);
  };

  const handleCancelCSCEdit = () => {
    setIsCSCEditModeEnabled(false);
    getClassSpecificCharacteristics(Id);
    setSelectAllStatus(null);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const openAddNewManufacturerModal = () => {
    setIsAddNewManufacturerModalOpen(true);
  };

  const closeAddNewManufacturerModal = () => {
    setIsAddNewManufacturerModalOpen(false);
    setNewManufacturerState({
      equipmentManufacturerPk: "",
      manufacturerNameRus: "",
      manufacturerWebSiteLink: "",
    });
    setIsManufacturerExists(false);
    setIsManufacturerNameRusExists(false);
    setIsManufacturerWebSiteLinkExists(false);
  };

  const isManufacturerDataAlreadyExists = (newValue, field) => {
    return allManufacturersList
      .map((item) => item[field]?.length && item[field]?.toLowerCase().trim())
      .includes(newValue.toLowerCase().trim());
  };

  const handleNewManufacturerNameEngChange = (event) => {
    const newManufacturerNameEng = event.target.value;
    const isExistsAlready = isManufacturerDataAlreadyExists(
      newManufacturerNameEng,
      "manufacturerNameEng"
    );
    setIsManufacturerExists(isExistsAlready);
    setNewManufacturerState({
      ...newManufacturerState,
      equipmentManufacturerPk: newManufacturerNameEng,
    });
  };

  const handleNewManufacturerNameRusChange = (event) => {
    const newManufacturerNameRus = event.target.value;
    const isExistsAlready = isManufacturerDataAlreadyExists(
      newManufacturerNameRus,
      "manufacturerNameRus"
    );
    setIsManufacturerNameRusExists(isExistsAlready);
    setNewManufacturerState({
      ...newManufacturerState,
      manufacturerNameRus: event.target.value,
    });
  };

  const handleNewManufacturerWebSiteLinkChange = (event) => {
    const newManufacturerWebSiteLink = event.target.value;
    const isExistsAlready = isManufacturerDataAlreadyExists(
      newManufacturerWebSiteLink,
      "manufacturerWebSiteLink"
    );
    setIsManufacturerWebSiteLinkExists(isExistsAlready);
    setNewManufacturerState({
      ...newManufacturerState,
      manufacturerWebSiteLink: event.target.value,
    });
  };

  const handleAddNewManufacturer = async () => {
    setLoading(true);
    await addNewManufacturer(newManufacturerState)
      .then(() => {
        setSnackbarMessage("New Manufacturer added successfully");
        setSnackbarOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to add new manufacturer");
        setSnackbarOpen(true);
        setLoading(false);
      });
    await loadAllManufacturers();
    setIsAddNewManufacturerModalOpen(false);
    setNewManufacturerState({
      equipmentManufacturerPk: "",
      manufacturerNameRus: "",
      manufacturerWebSiteLink: "",
    });
  };

  const displayNonEditModeValue = ({
    characteristicName,
    characteristicsValue,
  }) => {
    if (characteristicName === "MANUFACTURER") {
      const manufacturerInfo = allManufacturersList.find(
        ({ manufacturerNameEng }) =>
          manufacturerNameEng === characteristicsValue
      );
      if (manufacturerInfo?.manufacturerStatus === MANUFACTURER_STATUS.NEW) {
        return (
          <Tooltip title="Manufacturer is not approved yet" placement="right">
            <span className="cursor-pointer text-lime-600">
              {characteristicsValue}
            </span>
          </Tooltip>
        );
      } else {
        return characteristicsValue;
      }
    } else {
      return characteristicsValue;
    }
  };

  const handlePicturesEdit = () => {
    setIsPicturesEditEnabled(true);
  };

  const handlePicturesUpdate = async () => {
    setIsPicturesEditEnabled(false);
    await deleteEquipmentImage(removedImageIds)
      .then(() => {
        setSnackbarOpen(true);
        setSnackbarMessage("Images updated successfully!");
      })
      .catch((error) => {
        console.log("Images error:", error);
        setSnackbarOpen(true);
        setSnackbarMessage("Something went wrong");
      });
    await getEquipmentImages([Id]);
  };

  const handleCancelPicturesEdit = () => {
    setIsPicturesEditEnabled(false);
    setRemovedImageIds([]);
    getEquipmentImage([Id]);
  };

  const handleRemoveImage = (imageId) => {
    setRemovedImageIds((prevIds) => [...prevIds, imageId]);
  };

  return (
    <div>
      <Title>
        <IconButton
          sx={{ color: blue[500] }}
          underline="hover"
          onClick={handleBack}
        >
          <ArrowBackIcon />
          {Id}
        </IconButton>
      </Title>
      <Card sx={{ minWidth: 275 }}>
        <CardContent></CardContent>
        <Box
          sx={{
            bgcolor: "background.paper",
            width: "100%",
            typography: "body1",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab sx={{ fontWeight: "bold" }} label="General" value="1" />
                <Tab
                  sx={{ fontWeight: "bold" }}
                  label="Class-Specific Characteristics"
                  value="2"
                />
                <Tab sx={{ fontWeight: "bold" }} label="Documents" value="3" />
                <Tab
                  sx={{ fontWeight: "bold" }}
                  label="Walkdown Pictures"
                  value="4"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid item xs={12} sx={{ position: "relative" }}>
                {!isGCEditModeEnabled &&
                  isAllowed &&
                  !isWalkdownCoordinator &&
                  !isMMAnalyst && (
                    <IconButton
                      onClick={handleGCEditMode}
                      sx={{ p: "10px", color: blue[500] }}
                      aria-label="EditIcon"
                      disabled={isCharacteristicsLoading}
                    >
                      <EditIcon /> Edit
                    </IconButton>
                  )}
                {isGCEditModeEnabled && (
                  <>
                    <IconButton
                      onClick={handleGeneralCharacteristicUpdate}
                      sx={{ p: "10px", color: blue[500] }}
                      aria-label="EditIcon"
                    >
                      <EditIcon />
                      Save
                    </IconButton>
                    <IconButton
                      onClick={handleCancelGCEdit}
                      sx={{ p: "10px", color: blue[500] }}
                      aria-label="CancelIcon"
                    >
                      <Cancel />
                      Cancel
                    </IconButton>
                  </>
                )}
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    opacity: isCharacteristicsLoading ? 0.5 : 1,
                    minHeight: "400px",
                  }}
                >
                  {isCharacteristicsLoading && (
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            color: "rgba(96, 96, 96)",
                            fontWeight: 700,
                          },
                        }}
                      >
                        <TableCell>Characteristics</TableCell>
                        <TableCell>Values</TableCell>
                        <TableCell>
                          {!isGCEditModeEnabled && "Status"}
                          {isGCEditModeEnabled && (
                            <Select
                              value={selectAllStatus}
                              onChange={handleGeneralStatuses}
                              options={getStatusOptionsByUserRole()}
                              placeholder="Select All Status:"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        position: "relative",
                        opacity: isLoading ? 0.5 : 1,
                      }}
                    >
                      {isLoading && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                      {generalCharacteristicsState.length
                        ? generalCharacteristicsState.map((row, index) => (
                            <TableRow hover={true} key={index}>
                              <TableCell>{row.description} </TableCell>

                              {isGCEditModeEnabled ? (
                                <TableCell>
                                  {row.characteristicName ===
                                    "PLANNER_GROUP" && (
                                    <Select
                                      value={
                                        selectedplanneroptions || {
                                          value: row.characteristicsValue,
                                          label: row.characteristicsValue,
                                        }
                                      }
                                      onChange={handleChangePlanner}
                                      options={planneroptions}
                                      isClearable
                                      loadingMessage={() => "Loading..."}
                                      noOptionsMessage={() =>
                                        "No options found"
                                      }
                                      placeholder="Select..."
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        control: (base) => ({
                                          ...base,
                                          width: "52%", // Set the width to 100% to align with the text boxes
                                        }),
                                      }}
                                    />
                                  )}
                                  {row.characteristicName === "STATUS" && (
                                    <Select
                                      value={
                                        selectedStatusOptions || {
                                          value: row.characteristicsValue,
                                          label: row.characteristicsValue,
                                        }
                                      }
                                      onChange={handleChangeStatus}
                                      options={statusoptions}
                                      isClearable
                                      loadingMessage={() => "Loading..."}
                                      noOptionsMessage={() =>
                                        "No options found"
                                      }
                                      placeholder="Select..."
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        control: (base) => ({
                                          ...base,
                                          width: "52%", // Set the width to 100% to align with the text boxes
                                        }),
                                      }}
                                    />
                                  )}
                                  {row.characteristicName === "COST_CENTER" && (
                                    <Select
                                      value={
                                        selectedOption || {
                                          value: row.characteristicsValue,
                                          label: row.characteristicsValue,
                                        }
                                      }
                                      onChange={handleCostCenterChange}
                                      options={options}
                                      isClearable
                                      loadingMessage={() => "Loading..."}
                                      noOptionsMessage={() =>
                                        "No options found"
                                      }
                                      placeholder="Select..."
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        control: (base) => ({
                                          ...base,
                                          width: "52%", // Set the width to 100% to align with the text boxes
                                        }),
                                      }}
                                    />
                                  )}
                                  {row.characteristicName ===
                                    "MANUFACTURER" && (
                                    <Box sx={{ display: "flex" }}>
                                      <VirtualizedAutocomplete
                                        options={manufacturerOptions}
                                        onChange={handleManufacturerChange}
                                        value={{
                                          value: row.characteristicsValue,
                                          label: row.characteristicsValue,
                                        }}
                                        loadingText="Loading..."
                                        sx={{ width: "222px" }}
                                        freeSolo
                                      />
                                      <button
                                        style={{
                                          color: blue[500],
                                          marginLeft: "50px",
                                          fontWeight: "bold",
                                        }}
                                        onClick={openAddNewManufacturerModal}
                                      >
                                        Add new
                                      </button>
                                    </Box>
                                  )}
                                  {row.characteristicName !== "COST_CENTER" &&
                                    row.characteristicName !==
                                      "PLANNER_GROUP" &&
                                    row.characteristicName !== "STATUS" &&
                                    row.characteristicName !== "MANUFACTURER" &&
                                    row.characteristicName !==
                                      "COMPANY_CODE" && (
                                      <>
                                        <TextField
                                          value={row.characteristicsValue}
                                          onChange={(event) =>
                                            handleInputChange(event, index)
                                          }
                                        />

                                        {!row.characteristicsValue && (
                                          <Typography
                                            variant="caption"
                                            color="error"
                                            sx={{
                                              marginTop: "4px",
                                              fontSize: "14px",
                                              display: "block",
                                              fontWeight: "bold",
                                            }}
                                          ></Typography>
                                        )}
                                      </>
                                    )}
                                  {row.characteristicName === "COMPANY_CODE" &&
                                    row.characteristicsValue}
                                </TableCell>
                              ) : (
                                // Render non-edit mode content here if needed
                                <TableCell>
                                  {displayNonEditModeValue(row)}
                                </TableCell>
                              )}
                              <TableCell>
                                {isGCEditModeEnabled && (
                                  <Select
                                    value={
                                      generalCharacteristicsState[index].status
                                        ? {
                                            label:
                                              generalCharacteristicsState[index]
                                                .status,
                                          }
                                        : null
                                    }
                                    onChange={(selectedOption) =>
                                      handleStatus(selectedOption, index)
                                    }
                                    options={getStatusOptionsByUserRole()}
                                    loadingMessage={() => "Loading..."}
                                    noOptionsMessage={() => "No options found"}
                                    placeholder="Select..."
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                  />
                                )}
                                {!isGCEditModeEnabled && row.status}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                  <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={2000}
                    message={snackbarMessage}
                    onClose={handleClose}
                    style={{
                      position: "absolute",
                      bottom: "94%",
                      left: "77%",
                      height: "max-content",
                    }}
                  />
                </Paper>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid item xs={12} sx={{ position: "relative" }}>
                {!isCSCEditModeEnabled &&
                  isAllowed &&
                  !isWalkdownCoordinator &&
                  !isMMAnalyst && (
                    <IconButton
                      onClick={handleCSCEditMode}
                      sx={{ p: "10px", color: blue[500] }}
                      aria-label="EditIcon"
                    >
                      <EditIcon /> Edit
                    </IconButton>
                  )}
                {isCSCEditModeEnabled && (
                  <>
                    <IconButton
                      onClick={handleSpecificCharacteristicUpdate}
                      sx={{ p: "10px", color: blue[500] }}
                      aria-label="EditIcon"
                    >
                      <EditIcon />
                      Save
                    </IconButton>
                    <IconButton
                      onClick={handleCancelCSCEdit}
                      sx={{ p: "10px", color: blue[500] }}
                      aria-label="CancelIcon"
                    >
                      <Cancel />
                      Cancel
                    </IconButton>
                  </>
                )}
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    opacity: isClassSpecificLoading ? 0.5 : 1,
                    minHeight: "400px",
                  }}
                >
                  {isClassSpecificLoading && (
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            color: "rgba(96, 96, 96)",
                            fontWeight: 700,
                          },
                        }}
                      >
                        <TableCell>Characteristics</TableCell>
                        <TableCell>Values</TableCell>
                        <TableCell>
                          {!isCSCEditModeEnabled && "Status"}
                          {isCSCEditModeEnabled && (
                            <Select
                              value={selectAllStatus}
                              onChange={handleClassSpecificStatuses}
                              options={getStatusOptionsByUserRole()}
                              placeholder="Select All Status:"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        position: "relative",
                        opacity: isLoading ? 0.5 : 1,
                      }}
                    >
                      {isLoading && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                      {specificCharacteristicsState.length
                        ? specificCharacteristicsState.map((row, index) => (
                            <TableRow hover={true} key={index}>
                              <TableCell
                                style={{ width: "420px" }}
                              >{`${row.description} [${row.characteristicName}]`}</TableCell>

                              {isCSCEditModeEnabled ? (
                                <TableCell>
                                  {row.characteristicName ===
                                    "PLANNER_GROUP" && (
                                    <Select
                                      value={
                                        selectedplanneroptions || {
                                          value: row.characteristicsValue,
                                          label: row.characteristicsValue,
                                        }
                                      }
                                      onChange={handleChangePlanner}
                                      options={planneroptions}
                                      isClearable
                                      loadingMessage={() => "Loading..."}
                                      noOptionsMessage={() =>
                                        "No options found"
                                      }
                                      placeholder="Select..."
                                    />
                                  )}

                                  {row.characteristicName === "COST_CENTER" && (
                                    <Select
                                      value={
                                        selectedOption || {
                                          value: row.characteristicsValue,
                                          label: row.characteristicsValue,
                                        }
                                      }
                                      onChange={handleCostCenterChange}
                                      options={options}
                                      isClearable
                                      loadingMessage={() => "Loading..."}
                                      noOptionsMessage={() =>
                                        "No options found"
                                      }
                                      placeholder="Select..."
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        control: (base) => ({
                                          ...base,
                                          width: "46%", // Set the width to 100% to align with the text boxes
                                        }),
                                      }}
                                    />
                                  )}

                                  {row.characteristicName !== "COST_CENTER" &&
                                    row.characteristicName !==
                                      "PLANNER_GROUP" && (
                                      <>
                                        <TextField
                                          value={row.characteristicsValue}
                                          onChange={(event) =>
                                            handleInputChangeGeneral(
                                              event,
                                              index
                                            )
                                          }
                                        />

                                        {!row.characteristicsValue && (
                                          <Typography
                                            variant="caption"
                                            color="error"
                                            sx={{
                                              marginTop: "4px",
                                              fontSize: "14px",
                                              display: "block",
                                              fontWeight: "bold",
                                            }}
                                          ></Typography>
                                        )}
                                      </>
                                    )}
                                </TableCell>
                              ) : (
                                <TableCell>
                                  {row.characteristicsValue}
                                </TableCell>
                              )}
                              <TableCell>
                                {isCSCEditModeEnabled && (
                                  <Select
                                    value={
                                      specificCharacteristicsState[index].status
                                        ? {
                                            label:
                                              specificCharacteristicsState[
                                                index
                                              ].status,
                                          }
                                        : null
                                    }
                                    onChange={(selectedOption) =>
                                      handleStatusGeneral(selectedOption, index)
                                    }
                                    options={getStatusOptionsByUserRole()}
                                    loadingMessage={() => "Loading..."}
                                    noOptionsMessage={() => "No options found"}
                                    placeholder="Select..."
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                  />
                                )}
                                {!isCSCEditModeEnabled && row.status}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                  <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={2000}
                    message={snackbarMessage}
                    onClose={handleClose}
                    style={{
                      position: "absolute",
                      bottom: "94%",
                      left: "77%",
                      height: "max-content",
                    }}
                  />
                </Paper>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid item xs={12}>
                <Eportal />
              </Grid>
              <Stack direction="column" alignItems="center" spacing={2}></Stack>
            </TabPanel>
            <TabPanel value="4">
              <Grid item xs={12} sx={{ position: "relative", minHeight: 250 }}>
                {equipmentImageState && equipmentImageState.length
                  ? !isPicturesEditEnabled &&
                    (isAllowed || isWalkdownCoordinator) && (
                      <IconButton
                        onClick={handlePicturesEdit}
                        sx={{ p: "10px", color: blue[500] }}
                        aria-label="EditIcon"
                      >
                        <EditIcon /> Edit
                      </IconButton>
                    )
                  : null}
                {equipmentImageState && equipmentImageState.length
                  ? isPicturesEditEnabled && (
                      <>
                        <IconButton
                          onClick={handlePicturesUpdate}
                          sx={{ p: "10px", color: blue[500] }}
                          aria-label="EditIcon"
                        >
                          <EditIcon />
                          Save
                        </IconButton>
                        <IconButton
                          onClick={handleCancelPicturesEdit}
                          sx={{ p: "10px", color: blue[500] }}
                          aria-label="CancelIcon"
                        >
                          <Cancel />
                          Cancel
                        </IconButton>
                      </>
                    )
                  : null}
                <>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      {equipmentImageState && equipmentImageState.length ? (
                        <ImageGallery
                          imageList={equipmentImageState}
                          cols={4}
                          gap={20}
                          rowHeight={250}
                          withDescription={true}
                          onRemoveImage={handleRemoveImage}
                          isEditModeEnabled={isPicturesEditEnabled}
                        />
                      ) : (
                        "No photos yet"
                      )}
                    </Box>
                  )}
                </>
                <Snackbar
                  open={isSnackbarOpen}
                  autoHideDuration={2000}
                  message={snackbarMessage}
                  onClose={handleClose}
                  style={{
                    position: "absolute",
                    bottom: "94%",
                    left: "77%",
                    height: "max-content",
                  }}
                />
              </Grid>
              <Stack direction="column" alignItems="center" spacing={2}></Stack>
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
      <Modal
        open={isAddNewManufacturerModalOpen}
        onClose={closeAddNewManufacturerModal}
      >
        <Box sx={modalStyle}>
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            New Manufacturer
          </Typography>
          <Box sx={modalTextFieldStyle}>
            <Typography>Name</Typography>
            <CustomTextField
              error={isManufacturerExists}
              helperText={isManufacturerExists ? "Name already exists" : ""}
              value={newManufacturerState.equipmentManufacturerPk}
              sx={{ width: "200px" }}
              size="small"
              onChange={handleNewManufacturerNameEngChange}
              disabled={isLoading}
            />
          </Box>
          <Box sx={modalTextFieldStyle}>
            <Typography>Name in Russian</Typography>
            <CustomTextField
              error={isManufacturerNameRusExists}
              helperText={
                isManufacturerNameRusExists ? "Name already exists" : ""
              }
              value={newManufacturerState.manufacturerNameRus}
              sx={{ width: "200px" }}
              size="small"
              onChange={handleNewManufacturerNameRusChange}
              disabled={isLoading}
            />
          </Box>
          <Box sx={modalTextFieldStyle}>
            <Typography>Web site</Typography>
            <CustomTextField
              error={isManufacturerWebSiteLinkExists}
              helperText={
                isManufacturerWebSiteLinkExists ? "Site already exists" : ""
              }
              value={newManufacturerState.manufacturerWebSiteLink}
              sx={{ width: "200px" }}
              size="small"
              onChange={handleNewManufacturerWebSiteLinkChange}
              disabled={isLoading}
            />
          </Box>
          <Button
            sx={{ width: "100%", height: "40px", marginBottom: "15px" }}
            variant="contained"
            disabled={
              !newManufacturerState.equipmentManufacturerPk ||
              !newManufacturerState.manufacturerNameRus ||
              !newManufacturerState.manufacturerWebSiteLink ||
              isManufacturerExists ||
              isManufacturerNameRusExists ||
              isManufacturerWebSiteLinkExists ||
              isLoading
            }
            onClick={handleAddNewManufacturer}
          >
            {!isLoading ? "Send a request" : "Pending..."}
          </Button>
          <Typography className="text-center text-base">
            After request is approved you will be able to select manufacturer
            from the list
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
