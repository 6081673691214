import { useState } from "react";
import LightBox from "react-image-lightbox";
import "./style.css";

export const ImageLightBoxGallery = ({
  images,
  index,
  isImageOpen,
  setIsImageOpen,
}) => {
  const [photoIndex, setPhotoIndex] = useState(index);

  return (
    isImageOpen && (
      <LightBox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={() => {
          setPhotoIndex(0);
          setIsImageOpen(false);
        }}
        onMovePrevRequest={() =>
          setPhotoIndex((photoIndex + images.length - 1) % images.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % images.length)
        }
      />
    )
  );
};
