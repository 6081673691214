import { useCallback, useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { SCOPE } from "../../../constants";

export const useEquipment = () => {
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [isAreaCodeLoading, setIsAreaCodeLoading] = useState(false);
  const [isEquipmentsLoading, setIsEquipmentsLoading] = useState(false);
  const [isCharacteristicsLoading, setIsCharacteristicsLoading] =
    useState(false);
  const [isClassSpecificLoading, setIsClassSpecificLoading] = useState(false);

  const acquireAccessToken = useCallback(async () => {
    if (accounts.length === 0) {
      throw new Error("User is not signed in");
    }
    const request = {
      scopes: [SCOPE],
      account: accounts[0],
    };

    const authResult = await instance.acquireTokenSilent(request);

    return authResult.accessToken;
  }, [accounts, instance]);

  const getAllDataByColumn = useCallback(
    async (columnName) => {
      try {
        setLoading(true);
        const accessToken = await acquireAccessToken();
        let response = await axios.get(
          `${process.env.REACT_APP_APIBaseURL}/api/Equipment/GetAllDataByColumn`,
          {
            params: { columnName },
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setLoading(false);
        return response.data;
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
        throw error;
      }
    },
    [acquireAccessToken]
  );

  const handleSaveWalkdownUser = async (newUser) => {
    const accessToken = await acquireAccessToken();
    axios.post(
      `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/SaveUser`,
      // Only include userId if it is not empty
      newUser.userPk ? newUser : { ...newUser, userPk: null },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  const getAllEquipmentManufacturers = async () => {
    try {
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetAllEquipmentManufacturers`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const addNewManufacturer = async (dataParam) => {
    try {
      const accessToken = await acquireAccessToken();
      const response = await axios.post(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/AddNewEquipmentManufacturer`,
        dataParam,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  };

  const handleDeleteWalkdownUser = async (userPk) => {
    const accessToken = await acquireAccessToken();
    await axios.delete(
      `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/DeleteUser?user=${userPk}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  const onDeleteEquipment = async (equipmentId) => {
    const accessToken = await acquireAccessToken();
    await axios.delete(
      `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/DeleteEquipment?equipmentWalkdownId=${equipmentId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  const getGeneralCharcteristicsWithToken = async (equipmentTagId) => {
    try {
      setIsCharacteristicsLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetbyEquipmentTagId`,
        {
          params: { equipmentTagId },
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsCharacteristicsLoading(false);
      return response.data;
    } catch (error) {
      setIsCharacteristicsLoading(false);
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const getEquipmentsOptions = async (search) => {
    try {
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/GetEquipmentsOptions`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            search,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const getEquipmentsAreaCodes = async () => {
    try {
      setIsAreaCodeLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetAreaCode`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsAreaCodeLoading(false);
      return response.data;
    } catch (error) {
      setIsAreaCodeLoading(false);
      console.error("Error fetching area codes", error);
      throw error;
    }
  };

  const getEquipmentImage = async (equipmentTags) => {
    try {
      setLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetEquipmentImage`,
        {
          headers: {
            equipmentTags,
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching image link:", error);
      throw error;
    }
  };

  const deleteEquipmentImage = async (equipmentImageIds) => {
    try {
      const accessToken = await acquireAccessToken();
      await axios.delete(
        `${process.env.REACT_APP_APIBaseURL}/api/Equipment/DeleteEquipmentImage`,
        {
          headers: {
            equipmentImageIds,
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error when deleting image", error);
      throw error;
    }
  };

  const getWSStatusAndCompletionDateWithToken = async (equipmentTagId) => {
    try {
      setLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetWSStatusAndCompletionDate`,
        {
          params: { equipmentTagId },
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error: ", error);
      throw error;
    }
  };

  const getSpecificCharcteristicsWithToken = async (equipmentTagId) => {
    try {
      setIsClassSpecificLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetGeneralEquipmentTagId`,
        {
          params: { equipmentTagId },
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsClassSpecificLoading(false);
      return response.data;
    } catch (error) {
      setIsClassSpecificLoading(false);
      console.error("Error: ", error);
      throw error;
    }
  };

  const getWalkDownUserWithToken = async () => {
    try {
      setLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/GetWalkDownUsers`,
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error: ", error);
      throw error;
    }
  };

  const getWalkDownMasterToken = async (role) => {
    try {
      setLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/GetWalkDownScheduleMaster`,
        {
          params: { role },
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error: ", error);
      throw error;
    }
  };

  const getWalkDownScheduleEquipmentToken = async (
    scheduleMasterId,
    pageNumber,
    recordsCount
  ) => {
    try {
      setLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/GetWalkDownScheduleEquipment`,
        {
          params: {
            scheduleMasterId,
            pageNumber: pageNumber + 1,
            recordsCount,
          },
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error: ", error);
      throw error;
    }
  };

  const getEquipment = async (params) => {
    try {
      setIsEquipmentsLoading(true);
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Equipment/GetElements`,
        {
          params,
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsEquipmentsLoading(false);
      return response.data;
    } catch (error) {
      setIsEquipmentsLoading(false);
      console.error("Error: ", error);
      throw error;
    }
  };

  const getEquipmentToExport = async (params, signal) => {
    try {
      const accessToken = await acquireAccessToken();
      let response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Equipment/GetElements`,
        {
          params,
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error.message === "canceled") return;
      console.log("Error: ", error);
    }
  };

  const updateWalkdownSchedulesStatus = async (dataParam) => {
    try {
      const accessToken = await acquireAccessToken();
      const response = await axios.put(
        `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/UpdateScheduleStatus`,
        dataParam,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  };

  const updateCharacteristics = async (dataParam) => {
    try {
      const accessToken = await acquireAccessToken();
      const response = await axios.post(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/UpdateCharacteristics`,
        dataParam,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  };

  const getDCAMStatus = async () => {
    try {
      const accessToken = await acquireAccessToken();
      const response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetDCAMStatus`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  const getCostCenterOptions = async () => {
    try {
      const accessToken = await acquireAccessToken();
      const response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetCostCenterOptions`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  const getPlannerGroupOptions = async () => {
    try {
      const accessToken = await acquireAccessToken();
      const response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/Characteristics/GetPlannerGroupOptions`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  return {
    loading,
    isEquipmentsLoading,
    isAreaCodeLoading,
    isCharacteristicsLoading,
    isClassSpecificLoading,
    getDCAMStatus,
    getAllDataByColumn,
    getEquipmentsOptions,
    getEquipment,
    getEquipmentToExport,
    onDeleteEquipment,
    handleSaveWalkdownUser,
    getPlannerGroupOptions,
    handleDeleteWalkdownUser,
    getCostCenterOptions,
    getAllEquipmentManufacturers,
    addNewManufacturer,
    getEquipmentsAreaCodes,
    getWalkDownMasterToken,
    getWalkDownUserWithToken,
    updateCharacteristics,
    updateWalkdownSchedulesStatus,
    getGeneralCharcteristicsWithToken,
    getEquipmentImage,
    deleteEquipmentImage,
    getWalkDownScheduleEquipmentToken,
    getSpecificCharcteristicsWithToken,
    getWSStatusAndCompletionDateWithToken,
  };
};
