import React, { memo, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { List } from "react-virtualized";

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 40;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={250}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props) => {
            return React.cloneElement(children[props.index], {
              style: {
                ...props.style,
                padding: "0 10px",
                fontSize: "12px",
              },
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

export const VirtualizedAutocomplete = memo(
  ({
    key,
    label,
    getOptionLabel,
    filterParamsByColumn,
    rowKey,
    onValueChange,
    searchValue,
    setSearchValue,
    isResetClicked,
    ...props
  }) => {
    return (
      <Autocomplete
        value={searchValue}
        filterOptions={filterOptions}
        ListboxComponent={ListboxComponent}
        getOptionLabel={(option) =>
          getOptionLabel
            ? getOptionLabel(option)
            : option.label ||
              (isResetClicked ? "" : searchValue || (option.label ?? ""))
        }
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={label} fullWidth />
        )}
        {...props}
        key={isResetClicked}
      />
    );
  }
);
