export const materialsManagementColumns = [
  {
    field: "stockCode",
    headerName: "Stock Code",
    width: 150,
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",
    width: 200,
  },
  {
    field: "manufacturerPartNumber",
    headerName: "Manufacturer Part Number",
    width: 250,
  },
  {
    field: "purchaseOrderNumber",
    headerName: "Purchase Order Number",
    width: 200,
  },
  {
    field: "purchaseOrderNumberLine",
    headerName: "Purchase Order Number Line",
    width: 200,
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
  },
  {
    field: "customDeclarationNumber",
    headerName: "Custom Declaration Number",
    width: 200,
  },
];

export const excelFileConfig = {
  columnNames: [
    "Stock Code",
    "Manufacturer",
    "Manufacturer Part Number",
    "Purchase Order Number",
    "Purchase Order Number Line",
    "Description",
    "Custom Declaration Number",
  ],
  keys: [
    "stockCode",
    "manufacturer",
    "manufacturerPartNumber",
    "purchaseOrderNumber",
    "purchaseOrderNumberLine",
    "description",
    "customDeclarationNumber",
  ],
  fileName: "Materials_Management.xlsx",
  sheetName: "Materials Management Info",
};
