import { useState, memo } from "react";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  TableContainer,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Chip,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  setFilteredValuesByColumn,
  getEquipmentsParams,
  setEquipmentsParams,
  getRecentlyUsedFilters,
  setRecentlyUsedFilters,
} from "../../store/slices/equipmentsFilterSlice";
import {
  Refresh as RefreshIcon,
  Home,
  FilterList as FilterListIcon,
  ViewColumn as ViewColumnIcon,
  FileDownload as FileDownloadIcon,
  TrendingFlat as TrendingFlatIcon,
} from "@mui/icons-material";
import { EQUIPMENTS_TABLE_COLUMN_LABELS } from "../../constants";
import { FilterByColumnData } from "./FilterByColumnData";
import { FilterByColumns } from "./FilterByColumns";

export const Equipmentfilter = memo(
  ({
    listData,
    filterRows,
    setFilterRows,
    refreshBtnClick,
    handleHomeButton,
    handleDownloadCSV,
    handleDownloadExcel,
    handleSelectedColumn,
    resetAllAppliedFilters,
    filterParamsByColumn,
    setFilterParamsByColumn,
    isExcelExportLoading,
    isCSVExportLoading,
  }) => {
    const dispatch = useDispatch();
    const equipmentsParams = useSelector(getEquipmentsParams);
    const recentlyUsedFilters = useSelector(getRecentlyUsedFilters);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElExport, setAnchorElExport] = useState(null);
    const [anchorElColumn, setAnchorElColumn] = useState(null);
    const [isResetClicked, setIsResetClicked] = useState(false);
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const openExport = Boolean(anchorElExport);
    const idExport = openExport ? "simple-popover" : undefined;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleFieldChange = (field, rowKey) => {
      setFilterParamsByColumn({
        ...(filterParamsByColumn ?? filterParamsByColumn),
        [rowKey]: {
          ...filterParamsByColumn?.[rowKey],
          field,
        },
      });
    };

    const handleOperatorChange = (operator, rowKey) => {
      setFilterParamsByColumn({
        ...(filterParamsByColumn ?? filterParamsByColumn),
        [rowKey]: {
          ...filterParamsByColumn?.[rowKey],
          operator,
        },
      });
    };

    const handleValueChange = (value, rowKey) => {
      setFilterParamsByColumn({
        ...(filterParamsByColumn ?? filterParamsByColumn),
        [rowKey]: {
          ...filterParamsByColumn?.[rowKey],
          value,
        },
      });
    };

    const handleFiltersBtnClick = () => {
      setAnchorEl(document.getElementById("root"));
    };

    const handleFiltersDialogClose = () => {
      setAnchorEl(null);
    };

    const handleDownloadTable = (event) => {
      setAnchorElExport(event.currentTarget);
    };

    const handleHomeButtonClick = () => {
      handleHomeButton();
    };

    const resetAllFilters = () => {
      resetAllAppliedFilters();
      setIsResetClicked(true);
    };

    const handleCloseDownloadTable = () => {
      setAnchorElExport(null);
    };

    const handleAddFilterClick = () => {
      const newRowKey = filterRows.length + 1;
      setFilterRows([...filterRows, { key: newRowKey }]);
    };

    const handleSelectColumnsClick = (event) => {
      setAnchorElColumn(event.currentTarget);
    };

    const getFilteredValuesByColumns = (filterParams) => {
      if (filterParams) {
        const arrayOfFilters = Object.values(filterParams).map(
          ({ field, operator, value }) => `${field},${operator},${value}`
        );
        const uniqueFilterValues = Array.from(new Set(arrayOfFilters)).join(
          ";"
        );

        if (equipmentsParams.FilteredColums) {
          const allFilters = Array.from(
            new Set([
              ...equipmentsParams.FilteredColums.split(";"),
              uniqueFilterValues,
            ])
          ).join(";");
          return allFilters;
        } else {
          return uniqueFilterValues;
        }
      }
      return "";
    };

    const dispatchAppliedFilterParams = (filterParams) => {
      const filteredValues = getFilteredValuesByColumns(filterParams);
      dispatch(setFilteredValuesByColumn(filterParams));
      dispatch(
        setEquipmentsParams({
          ...equipmentsParams,
          FilteredColums: filteredValues,
        })
      );
    };

    const handleCloseSnackbar = () => {
      setIsSnackBarOpen(false);
    };

    const handleSubmit = () => {
      const allfilterValues = Object.values(filterParamsByColumn);
      const isValid = allfilterValues.every((filter) => {
        return Object.values(filter).every((value) => {
          const THREE_FIELDS = 3; // every field on a row filter should be filled with value
          const filterValuesPerRow = Object.values(filter).length;
          return value !== null && filterValuesPerRow === THREE_FIELDS;
        });
      });

      if (!isValid) {
        setIsSnackBarOpen(true);
        return;
      }
      dispatchAppliedFilterParams(filterParamsByColumn);
      dispatch(setRecentlyUsedFilters(filterParamsByColumn));
    };

    const handleRefreshFilter = () => {
      refreshBtnClick();
    };

    const handleRecentlyUsedFilterClick = (rowKey, value) => {
      setFilterParamsByColumn(() => {
        const updatedFilterParams = {
          [rowKey]: {
            ...value,
          },
        };
        dispatchAppliedFilterParams(updatedFilterParams);
        return updatedFilterParams;
      });
    };

    return (
      <Box
        sx={{ display: { xs: "none", md: "flex", justifyContent: "right" } }}
      >
        <>
          <Tooltip title="Go Home" placement="top">
            <IconButton
              onClick={handleHomeButtonClick}
              sx={{ ml: "5px", color: "#3b444b" }}
            >
              <Home sx={{ fontSize: "30px" }}></Home>
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter Table" placement="top">
            <IconButton
              onClick={handleFiltersBtnClick}
              sx={{ ml: "5px", color: "#3b444b" }}
            >
              <FilterListIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh table" placement="top">
            <IconButton
              onClick={handleRefreshFilter}
              sx={{ ml: "5px", color: "#3b444b" }}
            >
              <RefreshIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter columns" placement="top">
            <IconButton
              onClick={handleSelectColumnsClick}
              sx={{ ml: "5px", color: "#3b444b" }}
            >
              <ViewColumnIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Download Table"} placement="top">
            <IconButton
              onClick={handleDownloadTable}
              sx={{ ml: "5px", color: "#3b444b" }}
            >
              <FileDownloadIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Tooltip>
        </>
        <FilterByColumns
          listData={listData}
          setAnchorElColumn={setAnchorElColumn}
          anchorElColumn={anchorElColumn}
          handleSelectedColumn={handleSelectedColumn}
        />
        <Popover
          id={idExport}
          open={openExport}
          anchorEl={anchorElExport}
          onClose={handleCloseDownloadTable}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            <Button
              disabled={isExcelExportLoading}
              onClick={handleDownloadExcel}
              variant="text"
            >
              {isExcelExportLoading ? "Loading excel..." : "Download Excel"}
              {isExcelExportLoading && (
                <CircularProgress style={{ width: "15px", height: "15px" }} />
              )}
            </Button>
            <Button
              disabled={isCSVExportLoading}
              onClick={handleDownloadCSV}
              variant="text"
            >
              {isCSVExportLoading ? "Loading csv..." : "Download CSV"}
              {isCSVExportLoading && (
                <CircularProgress style={{ width: "15px", height: "15px" }} />
              )}
            </Button>
          </div>
        </Popover>
        <Popover
          id={id}
          open={open}
          onClose={handleFiltersDialogClose}
          anchorOrigin={{ horizontal: "center", vertical: "center" }}
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 450 }} aria-label="simple table">
              <TableBody>
                {filterRows.map((row, index) => (
                  <FilterByColumnData
                    key={row.key}
                    rowKey={row.key}
                    rowIndex={index}
                    filterRows={filterRows}
                    setFilterRows={setFilterRows}
                    onFieldChange={handleFieldChange}
                    onOperatorChange={handleOperatorChange}
                    onValueChange={handleValueChange}
                    filterParamsByColumn={filterParamsByColumn}
                    setFilterParamsByColumn={setFilterParamsByColumn}
                    isResetClicked={isResetClicked}
                    setIsResetClicked={setIsResetClicked}
                  />
                ))}
                <TableRow>
                  <TableCell
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                    align="left"
                  >
                    <Button
                      onClick={resetAllFilters}
                      color="error"
                      variant="outlined"
                    >
                      Reset all filters
                    </Button>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleAddFilterClick}
                    >
                      Add Filter
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      variant="contained"
                    >
                      Search
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ textAlign: "left", padding: "12px 14px" }}
                    colSpan={3}
                  >
                    {Object.values(recentlyUsedFilters).length ? (
                      <Chip
                        label="Recently used filters"
                        variant="outlined"
                        color="success"
                        style={{ width: "250px" }}
                      />
                    ) : null}
                    <List style={{ padding: 0 }}>
                      {Object.entries(recentlyUsedFilters).map(
                        ([key, item], index) => {
                          return (
                            <ListItem
                              key={index}
                              style={{ padding: "10px 0px 0px" }}
                            >
                              <Button
                                onClick={() =>
                                  handleRecentlyUsedFilterClick(key, item)
                                }
                                variant="contained"
                                size="small"
                                style={{ fontSize: "10px", fontWeight: "bold" }}
                              >
                                {EQUIPMENTS_TABLE_COLUMN_LABELS[item.field]}
                                {<TrendingFlatIcon />}
                                <span
                                  style={{
                                    color: "orange",
                                  }}
                                >
                                  {item.operator}
                                </span>
                                {<TrendingFlatIcon />}
                                {item.value === " " ? `" "` : item.value}
                              </Button>
                            </ListItem>
                          );
                        }
                      )}
                    </List>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Popover>
        <Snackbar
          message="Please fill all filter values"
          open={isSnackBarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        />
      </Box>
    );
  }
);
