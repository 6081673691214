import { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Box, Snackbar, Chip, Tooltip } from "@mui/material";
import { BackspaceSharp } from "@mui/icons-material";
import { utils, writeFile } from "xlsx";
import {
  getEquipmentsParams,
  getEquipmentCategories,
  setEquipmentsParams,
} from "../../store/slices/equipmentsFilterSlice";
import { Equipmentfilter } from "./Equipmentfilter";
import { AreaAndUnitSection } from "./AreaAndUnitSection";
import { SearchComponent } from "./SearchComponent";
import { EquipmentsTable } from "./EquipmentsTable";
import { useEquipment } from "../app/hooks/useEquipment";
import { useUserRole } from "../app/hooks/useUserRole";
import {
  EQUIPMENT_COLUMNS_BY_USER_ROLE,
  EQUIPMENTS_TABLE_COLUMN_LABELS,
  EQUIPMENT_FILTER_PARAMS,
} from "../../constants";
import { Alert } from "./Alert";

export const Equipments = () => {
  const [columns, setColumns] = useState(
    EQUIPMENT_COLUMNS_BY_USER_ROLE.DEFAULT_USER
  );
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [filterParamsByColumn, setFilterParamsByColumn] = useState(null);
  const [filterRows, setFilterRows] = useState([{ key: 1 }]);
  const [equipmentState, setEquipmentState] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dataCount, setDataCount] = useState(null);
  const [isExcelExportLoading, setIsExcelExportLoading] = useState(false);
  const [isCSVExportLoading, setIsCSVExportLoading] = useState(false);
  const { userRole } = useUserRole();
  const equipmentsParams = useSelector(getEquipmentsParams);
  const equipmentCategories = useSelector(getEquipmentCategories);
  const [areaCode, setAreaCode] = useState(null);
  const [unitCode, setUnitCode] = useState(null);

  const { getEquipment, isEquipmentsLoading, getEquipmentToExport } =
    useEquipment();

  useEffect(() => {
    handleFilterSubmit(equipmentsParams);
  }, [equipmentsParams]);

  // Memoize the columns value to prevent it from changing unnecessarily
  const memoizedColumns = useMemo(() => {
    return [...columns];
  }, []);
  const shallowCopy = _.clone(columns);

  async function handleFilterSubmit(model) {
    const data = await getEquipment(model);
    setEquipmentState(data.items);
    setDataCount(data.count);
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleAddColumn = (_field, headername, event) => {
    const MAX_COLUMNS = 9;

    const columnExists = columns.some(
      (column) => column.field === _field && column.isChecked === true
    );
    const checkedColumnsCount = columns.filter(
      (column) => column.isChecked
    ).length;

    if (checkedColumnsCount >= MAX_COLUMNS) {
      setOpenSnackbar(true);
      event.target.checked = false;
      return;
    }
    if (!columnExists) {
      const col = memoizedColumns.find((column) => {
        return column.field === _field;
      });

      const newColumns = [
        ...columns,
        {
          ...col,
          isChecked: true,
        },
      ];

      const sortedColumns = newColumns.sort((a, b) => a.order - b.order);
      setColumns(sortedColumns);
      localStorage.setItem(
        `selectedColumnsBy${userRole}`,
        JSON.stringify(sortedColumns)
      );
    }
  };

  const handleRemoveColumn = (columnField) => {
    const newColumns = columns.filter((column) => column.field !== columnField);
    const sortedColumns = newColumns.sort((a, b) => a.order - b.order);
    setColumns(sortedColumns);
    localStorage.setItem(
      `selectedColumnsBy${userRole}`,
      JSON.stringify(sortedColumns)
    );
  };

  const handleRefreshPage = () => {
    dispatch(
      setEquipmentsParams({
        ...equipmentsParams,
      })
    );
  };

  const fetchDataToBeExported = async () => {
    const response = await getEquipmentToExport({
      ...equipmentsParams,
      FetchRecord: dataCount,
    });

    if (response) {
      const headers = columns
        .filter((column) => column.isChecked && column.Visible)
        .map((item) => item.field);
      const filteredDataToExport = response.items.map((item) => {
        const dataItem = {};
        headers.forEach((key) => {
          if (item.hasOwnProperty(key)) {
            dataItem[key] = item[key];
          }
        });
        return dataItem;
      });

      return { filteredDataToExport, headers };
    }
  };

  const getDataReadyToExport = async (data, headers, format) => {
    const worksheet = utils.json_to_sheet(data);
    worksheet["!cols"] = data.map(() => ({ width: 25 }));
    const fileHeaders = headers.map(
      (item) => EQUIPMENTS_TABLE_COLUMN_LABELS[item]
    );
    utils.sheet_add_aoa(worksheet, [fileHeaders], {
      origin: "A1",
    });
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Equipments Data");
    writeFile(workbook, `EquipmentsData.${format}`);
  };

  const handleDownloadExcel = async () => {
    try {
      setIsExcelExportLoading(true);
      const { filteredDataToExport, headers } = await fetchDataToBeExported();
      await getDataReadyToExport(filteredDataToExport, headers, "xlsx");
      setIsExcelExportLoading(false);
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsExcelExportLoading(false);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      setIsCSVExportLoading(true);
      const { filteredDataToExport, headers } = await fetchDataToBeExported();
      await getDataReadyToExport(filteredDataToExport, headers, "csv");
      setIsCSVExportLoading(false);
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsCSVExportLoading(false);
    }
  };

  const handleHomeButton = () => {
    resetAllAppliedFilters();
  };

  const handleSelectedColumn = (event) => {
    const value = event.target.value;
    const headerName = event.target.name;

    const isChecked = event.target.checked;
    if (isChecked) {
      handleAddColumn(value, headerName, event);
    } else {
      handleRemoveColumn(value);
    }
  };

  const handleRemoveFilter = (fieldName, filterValue = "") => {
    let updatedParams = {};
    if (fieldName === EQUIPMENT_FILTER_PARAMS.CATEGORY_ID) {
      updatedParams = {
        CategoryId: undefined,
        ClassId: undefined,
        SubClassId: undefined,
      };
    } else if (fieldName === EQUIPMENT_FILTER_PARAMS.AREA_CODE) {
      setAreaCode(null);
      setUnitCode(null);
      updatedParams = {
        AreaCode: undefined,
        UnitCode: undefined,
      };
    } else if (fieldName === EQUIPMENT_FILTER_PARAMS.UNIT_CODE) {
      setUnitCode(null);
      updatedParams = {
        UnitCode: undefined,
      };
    } else if (fieldName === EQUIPMENT_FILTER_PARAMS.FILTERED_COLUMS) {
      const allFilteredValuesByColumns =
        equipmentsParams.FilteredColums.split(";");
      const updatedFilterValues = allFilteredValuesByColumns.filter(
        (value) => value !== filterValue
      );
      const updatedFilterParams = updatedFilterValues.join(";");
      updatedParams = {
        FilteredColums: updatedFilterParams,
      };
    } else {
      updatedParams = {
        [fieldName]: undefined,
      };
    }
    dispatch(
      setEquipmentsParams({
        ...equipmentsParams,
        ...updatedParams,
      })
    );
  };

  const displaySelectedCategoryLabel = () => {
    if (equipmentsParams.CategoryId) {
      const categoryMatched = equipmentCategories.find(
        (item) => item.catID === equipmentsParams.CategoryId
      );
      if (categoryMatched) {
        const category = categoryMatched.category;
        return (
          <Chip
            label={category}
            onDelete={() =>
              handleRemoveFilter(EQUIPMENT_FILTER_PARAMS.CATEGORY_ID)
            }
            color="info"
          />
        );
      }
    }
  };

  const displaySelectedObjectTypeLabel = () => {
    if (equipmentsParams.ClassId) {
      const objectTypeMatched = equipmentCategories.find(
        (item) => item.objTypeID === equipmentsParams.ClassId
      );
      if (objectTypeMatched) {
        const objectType = objectTypeMatched.objectType;
        return (
          <Chip
            label={objectType}
            onDelete={() =>
              handleRemoveFilter(EQUIPMENT_FILTER_PARAMS.CLASS_ID)
            }
            color="info"
          />
        );
      }
    }
  };

  const displaySelectedClassificationLabel = () => {
    if (equipmentsParams.SubClassId) {
      const classificationMatched = equipmentCategories.find(
        (item) => item.classID === equipmentsParams.SubClassId
      );
      if (classificationMatched) {
        const classification = classificationMatched.classification;
        return (
          <Chip
            label={classification}
            onDelete={() =>
              handleRemoveFilter(EQUIPMENT_FILTER_PARAMS.SUBCLASS_ID)
            }
            color="info"
          />
        );
      }
    }
  };

  const displaySelectedAreaLabel = () => {
    if (equipmentsParams.AreaCode) {
      return (
        <Chip
          label={`Area: ${equipmentsParams.AreaCode}`}
          onDelete={() => handleRemoveFilter(EQUIPMENT_FILTER_PARAMS.AREA_CODE)}
          color="warning"
        />
      );
    }
  };

  const displaySelectedUnitCodeLabel = () => {
    if (equipmentsParams.UnitCode) {
      return (
        <Chip
          label={`Unit: ${equipmentsParams.UnitCode}`}
          onDelete={() => handleRemoveFilter(EQUIPMENT_FILTER_PARAMS.UNIT_CODE)}
          color="warning"
        />
      );
    }
  };

  const parseFilteredValues = (string) => {
    const filterChunks = string.split(";").filter(Boolean);
    const filterChips = filterChunks.map((filterChunk) => {
      const [field, condition, value] = filterChunk.split(",");
      return {
        buttonLabel: `${EQUIPMENTS_TABLE_COLUMN_LABELS[field]}  ${condition}  ${
          value === " " ? `" "` : value
        }`,
        filterValue: `${field},${condition},${value}`,
      };
    });
    return filterChips;
  };

  const displayFilterByColumnLabel = () => {
    if (equipmentsParams.FilteredColums) {
      const filterChips = parseFilteredValues(equipmentsParams.FilteredColums);

      return filterChips.map(({ buttonLabel, filterValue }, index) => (
        <Chip
          label={buttonLabel}
          key={index}
          onDelete={() =>
            handleRemoveFilter(
              EQUIPMENT_FILTER_PARAMS.FILTERED_COLUMS,
              filterValue
            )
          }
          color="success"
        />
      ));
    }
  };

  const displayAreaAndCodeGroupLabel = () => {
    return !displaySelectedUnitCodeLabel() ? (
      displaySelectedAreaLabel()
    ) : (
      <Box
        sx={{
          display: "flex",
          padding: "5px",
          border: "1.5px dashed orange",
          borderRadius: "50px",
          gap: "10px",
        }}
      >
        {displaySelectedAreaLabel()}
        {displaySelectedUnitCodeLabel()}
      </Box>
    );
  };

  const displayCategoryGroupLabel = () => {
    return !displaySelectedObjectTypeLabel() &&
      !displaySelectedClassificationLabel() ? (
      displaySelectedCategoryLabel()
    ) : (
      <Box
        sx={{
          display: "flex",
          padding: "5px",
          border: "1.5px dashed gray",
          borderRadius: "50px",
          gap: "10px",
        }}
      >
        {displaySelectedCategoryLabel()}
        {displaySelectedObjectTypeLabel()}
        {displaySelectedClassificationLabel()}
      </Box>
    );
  };

  const resetAllAppliedFilters = () => {
    setSearchValue("");
    setUnitCode(null);
    setAreaCode(null);
    setFilterParamsByColumn(null);
    setFilterRows([{ key: 1 }]);
    dispatch(
      setEquipmentsParams({
        StartFrom: 0,
        FetchRecord: 25,
      })
    );
  };

  const displayResetAllLabel = () => {
    if (
      displayFilterByColumnLabel() ||
      displayCategoryGroupLabel() ||
      displayAreaAndCodeGroupLabel()
    ) {
      return (
        <Tooltip title="Reset all">
          <BackspaceSharp
            color="error"
            onClick={resetAllAppliedFilters}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      );
    }
  };

  const displayFilterPath = () => {
    return (
      <Box
        style={{
          display: "flex",
          marginBottom: "20px",
          gap: 10,
          alignItems: "center",
          maxWidth: "1000px",
          width: "100%",
          minHeight: "50px",
          height: "auto",
          flexWrap: "wrap",
        }}
      >
        {displayFilterByColumnLabel()}
        {displayCategoryGroupLabel()}
        {displayAreaAndCodeGroupLabel()}
        {displayResetAllLabel()}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          minWidth: 275,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchComponent
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Box>
      <AreaAndUnitSection
        areaCode={areaCode}
        setAreaCode={setAreaCode}
        unitCode={unitCode}
        setUnitCode={setUnitCode}
        equipmentState={equipmentState}
        isLoading={isEquipmentsLoading}
      />
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        {displayFilterPath()}
        <Box style={{ display: "flex", alignItems: "flex-end" }}>
          <Equipmentfilter
            listData={shallowCopy}
            handleSelectedColumn={handleSelectedColumn}
            handleDownloadCSV={handleDownloadCSV}
            handleDownloadExcel={handleDownloadExcel}
            handleFilterSubmit={handleFilterSubmit}
            refreshBtnClick={handleRefreshPage}
            handleHomeButton={handleHomeButton}
            resetAllAppliedFilters={resetAllAppliedFilters}
            filterParamsByColumn={filterParamsByColumn}
            setFilterParamsByColumn={setFilterParamsByColumn}
            filterRows={filterRows}
            setFilterRows={setFilterRows}
            isExcelExportLoading={isExcelExportLoading}
            isCSVExportLoading={isCSVExportLoading}
          />
        </Box>
      </Box>
      <EquipmentsTable
        equipmentState={equipmentState}
        setEquipmentState={setEquipmentState}
        columns={columns}
        setColumns={setColumns}
        dataCount={dataCount}
        setDataCount={setDataCount}
        isLoading={isEquipmentsLoading}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          Cannot add more than 9 columns
        </Alert>
      </Snackbar>
    </>
  );
};
