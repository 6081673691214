import React from "react";
import Iframe from "react-iframe";

export const PowerBI = () => {
  return (
    <Iframe
      url="https://app.powerbi.com/reportEmbed?reportId=926ff775-4bc8-41cf-9b7d-a6997bc373fc&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26"
      position="absolute"
      width="70%"
      id="myId"
      className="myClassname"
      height="80%"
      styles={{ height: "800px" }}
    />
  );
};