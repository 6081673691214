import { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Grid,
  Tooltip,
  Paper,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { Add, Delete, Edit, Save, Cancel } from "@mui/icons-material";
import { Goback } from "./Goback";
import { ScheduleImagesRender } from "./ScheduleImagesRender";
import Select from "react-select";
import { useUserRole } from "../app/hooks/useUserRole";
import { EQUIPMENT_STATUS, SCOPE } from "../../constants";
import { useEquipment } from "../app/hooks/useEquipment";
import { TablePaginationActions } from "./TablePaginationActions";

const schedulesStatusOptions = [
  { label: "planned", value: "planned" },
  { label: "completed", value: "completed" },
  { label: "submitted", value: "submitted" },
];

export const WalkdownScheduleEquipment = () => {
  const { instance, accounts } = useMsal();
  const { isAdmin, isUserRoleLoading, isWalkdownCoordinator } = useUserRole();
  const { scheduleId, userId } = useParams();
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [shouldReload, setShouldReload] = useState(1);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(null);
  const [updatedSchedules, setUpdatedSchedules] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [equipment, setEquipment] = useState({
    alreadyScheduledTags: [],
    doesntExistOnDatabaseTags: [],
    notScheduledTags: [],
  });
  const [equipmentImages, setEquipmentImages] = useState({});
  const [removedImageIds, setRemovedImageIds] = useState([]);

  const acquireAccessToken = useCallback(async () => {
    if (accounts.length === 0) {
      throw new Error("User is not signed in");
    }
    const request = {
      scopes: [SCOPE],
      account: accounts[0],
    };
    const authResult = await instance.acquireTokenSilent(request);
    return authResult.accessToken;
  }, [accounts, instance]);

  const {
    getWalkDownScheduleEquipmentToken,
    loading,
    onDeleteEquipment,
    getEquipmentsOptions,
    updateWalkdownSchedulesStatus,
    getEquipmentImage,
    deleteEquipmentImage,
  } = useEquipment();

  const handleOpenDialog = () => {
    setSearchQuery("");
    setEquipment({
      alreadyScheduledTags: [],
      doesntExistOnDatabaseTags: [],
      notScheduledTags: [],
    });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSearchQuery("");
    setSelectedEquipment([]);
    setSelectAll(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const Search = () => {
    loadPlannerGroupOptions();
  };

  const fetchOptionsDebounced = async () => {
    try {
      setLoading(true);
      const data = await getEquipmentsOptions(searchQuery);
      const transformedOptions = data.map((item) => ({
        value: item.value,
        label: item.label,
        area: item.area,
        code: item.code,
        equipmentStatus: item.equipmentStatus,
      }));

      const alreadyScheduledTags = transformedOptions.filter(
        (item) => item.equipmentStatus === EQUIPMENT_STATUS.TAG_IS_SCHEDULED
      );
      const doesntExistOnDatabaseTags = transformedOptions.filter(
        (item) => item.equipmentStatus === EQUIPMENT_STATUS.TAG_DOES_NOT_EXIST
      );
      const notScheduledTags = transformedOptions.filter(
        (item) => item.equipmentStatus === EQUIPMENT_STATUS.TAG_IS_NOT_SCHEDULED
      );

      setEquipment({
        alreadyScheduledTags,
        doesntExistOnDatabaseTags,
        notScheduledTags,
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const loadPlannerGroupOptions = async () => {
    try {
      if (searchQuery === null || searchQuery === "") {
        return;
      }
      setEquipment({
        alreadyScheduledTags: [],
        doesntExistOnDatabaseTags: [],
        notScheduledTags: [],
      });

      fetchOptionsDebounced();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {}, [searchQuery, shouldReload]);

  const handleCheckboxChange = (value) => {
    setSelectedEquipment((prevSelectedEquipment) => {
      if (prevSelectedEquipment.includes(value)) {
        return prevSelectedEquipment.filter((item) => item !== value);
      } else {
        return [...prevSelectedEquipment, value];
      }
    });
  };

  const handleDeleteEquipment = (equipmentId) => {
    onDeleteEquipment(equipmentId)
      .then(() => {
        setSnackbarVariant("success");
        setSnackbarMessage("Equipment deleted successfully!");
        setSnackbarOpen(true);
        fetchEquipmentTag(scheduleId, pageNumber, rowsPerPage);
      })
      .catch((error) => {
        setSnackbarVariant("error");
        setSnackbarMessage("Something went wrong");
        setSnackbarOpen(true);
        console.error(error);
      });
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedEquipment([]);
    } else {
      setSelectedEquipment(
        equipment.notScheduledTags.map((item) => item.value)
      );
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  const handleAddScheduleAndClose = async () => {
    try {
      await handlePostEquipment();
      setEquipment({
        alreadyScheduledTags: [],
        doesntExistOnDatabaseTags: [],
        notScheduledTags: [],
      });
      setSearchQuery("");
      fetchEquipmentTag(scheduleId, pageNumber, rowsPerPage);
      setDialogOpen(false);
    } catch (error) {
      console.error("Error fecting equipments", error);
      setSnackbarOpen(true);
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarVariant("error");
    } finally {
      setSelectAll(false);
    }
  };
  const handlePostEquipment = async () => {
    const accessToken = await acquireAccessToken();
    const apiUrl = `${process.env.REACT_APP_APIBaseURL}/api/WalkDownApp/PostEquipment`;
    const payload = {
      selectedEquipment: selectedEquipment,
      UserID: userId,
      ScheduleID: scheduleId,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          setSnackbarVariant("success");
          setSnackbarMessage("Equipment added successfully!");
          setSnackbarOpen(true);
          setSelectedEquipment([]);
          fetchEquipmentTag(scheduleId, 0, rowsPerPage);
          setShouldReload(shouldReload + 1);
        } else {
          console.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("An error occurred while posting equipment:", error);
      });
  };

  async function fetchEquipmentTag(scheduleId, pageNumber, rowsPerPage) {
    try {
      const response = await getWalkDownScheduleEquipmentToken(
        scheduleId,
        pageNumber,
        rowsPerPage
      );
      if (response) {
        const schedules = response.result;
        const equipmentTags = schedules.map(
          (schedule) => schedule.jdeEquipmentTag
        );
        const equipmentImages = await getEquipmentImage(equipmentTags);
        const imagesByTag = {};
        equipmentImages.forEach((item) => {
          imagesByTag[item.jdeEquipmentTag] = item.equipmentImage;
        });
        setEquipmentImages(imagesByTag);
        setSchedules(response.result);
        setTotalCount(response.totalTags);
      } else {
        setSchedules([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchEquipmentTag(scheduleId, pageNumber, rowsPerPage);
  }, [scheduleId, pageNumber, rowsPerPage]);

  const handleEditMode = () => {
    setIsEditModeEnabled(true);
  };

  const handleCancelEdit = () => {
    fetchEquipmentTag(scheduleId, pageNumber, rowsPerPage);
    setSelectAllStatus(null);
    setIsEditModeEnabled(false);
  };

  const handleStatus = (selectedOption, index) => {
    setUpdatedSchedules({
      ...updatedSchedules,
      ...(schedules[index].equipmentWalkdownStatus !== selectedOption.value && {
        [index]: {
          equipmentWalkDownSchedulePK:
            schedules[index].equipmentWalkdownShedulePk,
          walkDownStatus: selectedOption.value,
        },
      }),
    });
    const updatedState = [...schedules];
    updatedState[index].equipmentWalkdownStatus = selectedOption.value;
    setSchedules(updatedState);
  };

  const onRemoveImage = (imageId) => {
    setRemovedImageIds((prevIds) => [...prevIds, imageId]);
  };

  const showSuccessMessage = () => {
    setSnackbarOpen(true);
    setSnackbarMessage("Data updated successfully!");
  };

  const getRemovedImageIds = () => {
    return schedules
      .map((schedule) => {
        return equipmentImages[schedule.jdeEquipmentTag]
          ?.filter((image) => removedImageIds.includes(image.equipmentImageId))
          .map((image) => image.equipmentImageId);
      })
      .filter((image) => image)
      .flat();
  };

  const handleSave = async () => {
    setLoading(true);
    setIsEditModeEnabled(false);
    const imagesIdsToRemove = getRemovedImageIds();
    if (Object.values(updatedSchedules).length && imagesIdsToRemove.length) {
      await deleteEquipmentImage(imagesIdsToRemove)
        .then(showSuccessMessage)
        .catch(() => {
          setSnackbarOpen(true);
          setSnackbarMessage("Something went wrong when deleting images");
        })
        .finally(() => {
          setLoading(false);
        });
      await updateWalkdownSchedulesStatus(Object.values(updatedSchedules))
        .then(showSuccessMessage)
        .catch(() => {
          setSnackbarOpen(true);
          setSnackbarMessage("Something went wrong when updating status");
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (Object.values(updatedSchedules).length) {
      await updateWalkdownSchedulesStatus(Object.values(updatedSchedules))
        .then(showSuccessMessage)
        .finally(() => {
          setLoading(false);
        });
    } else if (imagesIdsToRemove.length) {
      await deleteEquipmentImage(imagesIdsToRemove)
        .then(showSuccessMessage)
        .finally(() => {
          setLoading(false);
        });
    }
    await fetchEquipmentTag(scheduleId, pageNumber, rowsPerPage).finally(() => {
      setLoading(false);
    });
  };

  const handleSelectAllStatus = (selectedOption) => {
    setSelectAllStatus(selectedOption);
    const updatedState = schedules.map((schedule) => ({
      ...schedule,
      equipmentWalkdownStatus:
        schedule.equipmentWalkdownStatus === "completed"
          ? schedule.equipmentWalkdownStatus
          : selectedOption.value,
    }));
    const updatedSchedules = schedules
      .filter(
        (item) =>
          item.equipmentWalkdownStatus !== selectedOption.value &&
          item.equipmentWalkdownStatus !== "completed"
      )
      .map((schedule) => ({
        equipmentWalkDownSchedulePK: schedule.equipmentWalkdownShedulePk,
        walkDownStatus: selectedOption.value,
      }));
    setUpdatedSchedules(updatedSchedules);
    setSchedules(updatedState);
  };

  const displayAlreadyScheduledTags = () => {
    return equipment.alreadyScheduledTags.length ? (
      <TableCell colSpan={4}>
        Equipment with Tag number{" "}
        {equipment.alreadyScheduledTags.map((item) => item.label).join(", ")}{" "}
        already passed through the Walkdown.
      </TableCell>
    ) : null;
  };

  const displayDoesntExistOnDatabaseTags = () => {
    return equipment.doesntExistOnDatabaseTags.length ? (
      <TableCell colSpan={4}>
        Equipment with Tag number{" "}
        {equipment.doesntExistOnDatabaseTags
          .map((item) => item.label)
          .join(", ")}{" "}
        doesn't exist in the database.
      </TableCell>
    ) : null;
  };

  const handlePageChange = (_event, page) => {
    setPageNumber(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  return (
    <div>
      <Goback></Goback>
      <Card>
        <CardContent sx={{ minHeight: 400 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="div" gutterBottom>
              Walkdown Schedule Equipment
            </Typography>
            <Button
              variant="contained"
              component="span"
              id="newuser"
              onClick={handleOpenDialog}
              startIcon={<Add />}
              disabled={
                isLoading ||
                isUserRoleLoading ||
                (!isAdmin && !isWalkdownCoordinator)
              }
            >
              Assign Tag
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Box>
              {(isAdmin || isWalkdownCoordinator) &&
                (!isEditModeEnabled ? (
                  <IconButton
                    onClick={handleEditMode}
                    sx={{ p: "10px", color: blue[500] }}
                    aria-label="EditIcon"
                  >
                    <Edit /> Edit
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      onClick={handleSave}
                      sx={{ p: "5px", color: blue[500] }}
                      aria-label="SaveIcon"
                    >
                      <Save />
                      Save
                    </IconButton>
                    <IconButton
                      onClick={handleCancelEdit}
                      sx={{ p: "5px", color: blue[500] }}
                      aria-label="CancelIcon"
                    >
                      <Cancel />
                      Cancel
                    </IconButton>
                  </>
                ))}
            </Box>
          </Box>
          <Table size="small" sx={{ minWidth: 600 }}>
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    color: "black", // Set color to black
                    fontWeight: "bold", // Set font weight to bold
                  },
                }}
              >
                <TableCell>Index</TableCell>
                <TableCell>TAG</TableCell>
                <TableCell>Pictures</TableCell>
                <TableCell>
                  {!isEditModeEnabled ? (
                    "Status"
                  ) : (
                    <Select
                      value={selectAllStatus}
                      onChange={handleSelectAllStatus}
                      options={schedulesStatusOptions}
                      placeholder="Select All Status:"
                    />
                  )}
                </TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <CircularProgress />
              ) : (
                schedules.map((schedule, index) => (
                  <TableRow key={schedule.equipmentWalkdownShedulePk}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {schedule.jE_EQUIPMENT_TAG !== null
                        ? schedule.jdeEquipmentTag
                        : null}
                    </TableCell>
                    <TableCell>
                      <ScheduleImagesRender
                        images={equipmentImages[schedule.jdeEquipmentTag]}
                        isEditModeEnabled={isEditModeEnabled}
                        onRemoveImage={onRemoveImage}
                      />
                    </TableCell>
                    <TableCell>
                      {isEditModeEnabled && (
                        <Select
                          value={
                            schedule.equipmentWalkdownStatus
                              ? {
                                  label: schedule.equipmentWalkdownStatus,
                                }
                              : null
                          }
                          onChange={(selectedOption) =>
                            handleStatus(selectedOption, index)
                          }
                          isDisabled={
                            schedule.equipmentWalkdownStatus === "completed"
                          }
                          options={schedulesStatusOptions}
                          loadingMessage={() => "Loading..."}
                          noOptionsMessage={() => "No options found"}
                          placeholder="Select..."
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      )}
                      {!isEditModeEnabled && schedule.equipmentWalkdownStatus}
                    </TableCell>
                    <TableCell>
                      {schedule.equipmentJdeArea !== null
                        ? schedule.equipmentJdeArea
                        : null}
                    </TableCell>
                    <TableCell>
                      {schedule.unitCode !== null ? schedule.unitCode : null}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Delete">
                        <span>
                          <IconButton
                            onClick={() =>
                              handleDeleteEquipment(
                                schedule.equipmentWalkdownShedulePk
                              )
                            }
                            sx={{ color: "red" }}
                            aria-label="menu"
                            disabled={
                              isUserRoleLoading ||
                              (!isAdmin && !isWalkdownCoordinator)
                            }
                          >
                            <Delete />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Assign TAG</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <TextField
                sx={{ margin: "10px 0" }}
                label="Search Equipment"
                disabled={isLoading}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={(e) => Search()}>
                Search
              </Button>
            </Grid>
            <Grid item>{isLoading && <CircularProgress />}</Grid>
          </Grid>
          {!equipment.alreadyScheduledTags.length &&
          !equipment.doesntExistOnDatabaseTags.length &&
          !equipment.notScheduledTags.length &&
          !isLoading ? (
            <Box
              sx={{
                color: "red",
                fontWeight: "bold",
                height: "20px",
                padding: "20px 0",
                fontSize: "12px",
              }}
            >
              No equipment found
            </Box>
          ) : null}
          <TableContainer component={Paper} style={{ maxHeight: "300px" }}>
            <Table
              stickyHeader
              sx={{
                fontSize: "12px",
              }}
            >
              {equipment.notScheduledTags.length ? (
                <>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          color: "black",
                          fontWeight: "bold",
                          "&:last-child td, &:last-child th": { border: 0 },
                        },
                      }}
                    >
                      <TableCell>Equipment Name</TableCell>
                      <TableCell>Unit Area</TableCell>
                      <TableCell>Unit Code</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {equipment.notScheduledTags.map((item) => (
                      <TableRow key={item.value}>
                        <TableCell>{item.label}</TableCell>
                        <TableCell>{item.area}</TableCell>
                        <TableCell>{item.code}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={selectedEquipment.includes(item.value)}
                            onChange={() => handleCheckboxChange(item.value)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              ) : null}
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            style={{ maxHeight: "200px", marginTop: "20px" }}
          >
            <Table>
              <TableBody>
                <TableRow sx={{ backgroundColor: "#e5f4d5" }}>
                  {displayAlreadyScheduledTags()}
                </TableRow>
                <TableRow sx={{ backgroundColor: "#f3d3d9" }}>
                  {displayDoesntExistOnDatabaseTags()}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handlePostEquipment}>
            Add
          </Button>
          <Button variant="contained" onClick={handleAddScheduleAndClose}>
            Add & Close
          </Button>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        color={snackbarVariant}
      />
      <TablePagination
        style={{ display: "block", width: "100%" }}
        rowsPerPageOptions={[10, 25, 50, 100]}
        colSpan={3}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
};
