import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectUserRole,
  isUserRoleDataLoading,
} from "../../../store/slices/userSlice";
import { USER_ROLES, EQUIPMENT_COLUMNS_BY_USER_ROLE } from "../../../constants";

export const useUserRole = () => {
  const [role, setRole] = useState("");
  const userRole = useSelector(selectUserRole);
  const isUserRoleLoading = useSelector(isUserRoleDataLoading);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isWalkdownCoordinator, setIsWalkdownCoordinator] = useState(false);
  const [isMMAnalyst, setIsMMAnalyst] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [equipmentColumnsByRole, setEquipmentColumnsByRole] = useState([]);

  useLayoutEffect(() => {
    if (userRole) {
      setIsAllowed(
        userRole !== USER_ROLES.RESTRICTED || userRole === USER_ROLES.ADMIN
      );
      setIsWalkdownCoordinator(userRole === USER_ROLES.WALKDOWN_COORDINATOR);
      setIsMMAnalyst(userRole === USER_ROLES.MM_SYSTEM_ANALYST);
      setIsAdmin(userRole === USER_ROLES.ADMIN);
      setRole(userRole);
      setEquipmentColumnsByRole(
        userRole === USER_ROLES.SME
          ? EQUIPMENT_COLUMNS_BY_USER_ROLE.SME_USER
          : EQUIPMENT_COLUMNS_BY_USER_ROLE.DEFAULT_USER
      );
    }
  }, [userRole]);

  return {
    userRole: role,
    isUserRoleLoading,
    isAllowed,
    isMMAnalyst,
    isWalkdownCoordinator,
    isAdmin,
    equipmentColumnsByRole,
  };
};
