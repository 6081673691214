import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Title from "./Title";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserRole } from "../app/hooks/useUserRole";

export default function MainDash() {
  const navigate = useNavigate();
  const { isMMAnalyst, isAdmin } = useUserRole();
  const handleWorkWithData = () => {
    navigate("../equipments");
  };
  const handleMonitorClick = () => {
    navigate("../PowerBI");
  };
  const handleWalkDownUser = () => {
    navigate("../WalkdownSchedules");
  };

  const handleMaterialsManagementClick = () => {
    navigate("../MaterialsManagement");
  };

  return (
    <div>
      <Title>Dashboard</Title>
      <Card sx={{ minWidth: 275 }}>
        <CardContent></CardContent>
        <CardActions sx={{ justifyContent: "center", pb: 6 }}>
          <Button
            onClick={handleWorkWithData}
            sx={{
              pr: 2,

              color: "#fff",
              textDecoration: "none",
            }}
            size="large"
            variant="contained"
          >
            Work with data
          </Button>
          <Button variant="outlined" onClick={handleMonitorClick}>
            Monitor
          </Button>
          <Button variant="outlined" onClick={handleWalkDownUser}>
            WalkDown Management
          </Button>
          {(isMMAnalyst || isAdmin) && (
            <Button variant="outlined" onClick={handleMaterialsManagementClick}>
              Materials Management
            </Button>
          )}
          <Outlet />
        </CardActions>
      </Card>
    </div>
  );
}
