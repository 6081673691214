export const SCOPE = "https://tcodcam-prod-backend.tengizchevroil.com/Read";

export const USER_ROLES_ID = {
  "55838326-29bd-4a21-aaa1-7a001914344d": "Restricted user",
  "762b8087-02fe-4bd0-a4a7-91a20934f9ad": "Data team user",
  "e67cce2a-6f04-423a-bbd8-baca2fc7b5fc": "Enrichment user",
  "b9632174-c057-4f7e-951b-be3adc52bfe6": "SME user",
  "18d14569-c3bd-439b-9a66-3a2aee01d14f": "Walkdown coordinator",
  "8b8d6057-2d90-4b9b-aa36-505a943cee27": "Admin",
  "6ddb66f2-c97e-4bcc-95d9-0a9b83ac46ff": "MM system analyst",
};

export const USER_ROLES = {
  RESTRICTED: "Restricted user",
  DATA_TEAM: "Data team user",
  ENRICHMENT_TEAM: "Enrichment user",
  SME: "SME user",
  WALKDOWN_COORDINATOR: "Walkdown coordinator",
  ADMIN: "Admin",
  MM_SYSTEM_ANALYST: "MM system analyst",
};

export const RESOURCE_ID = "9bf5492c-a360-4fdd-96c8-bf10688b0c9a";

export const ATRIBUTE_STATUS = {
  JDE_E1_ORIGINAL: "JDE E1 original",
  IN_SCOPE_OF_DATA_SCRAPPING: "In scope of data scrapping",
  DATA_SCRAPPING_COMPLETED: "Data scrapping completed",
  IN_SCOPE_FOR_ENRICHMENT: "In scope for enrichment",
  ENRICHMENT_COMPLETED: "Enrichment completed",
  UNDER_DISCIPLINE_SME_REVIEW: "Under discipline SME review",
  DISCIPLINE_SME_REVIEW_COMPLETED: "Discipline SME review completed",
  ACD_COMPLETED: "ACD completed",
  UNCONFIRMED: "Unconfirmed",
};

export const OPERATOR = [
  {
    field: "=",
    headerName: "Equals",
  },
  {
    field: "%",
    headerName: "Contains",
  },
  {
    field: "%S",
    headerName: "Starts With",
  },

  {
    field: "%E",
    headerName: "Ends With",
  },
  {
    field: "%E",
    headerName: "Greater than",
  },
  {
    field: "%E",
    headerName: "Less than",
  },
];

export const EQUIPMENT_FILTER_PARAMS = {
  CATEGORY_ID: "CategoryId",
  CLASS_ID: "ClassId",
  SUBCLASS_ID: "SubClassId",
  AREA_CODE: "AreaCode",
  UNIT_CODE: "UnitCode",
  FILTERED_COLUMS: "FilteredColums",
  SEARCH: "Search",
};

export const EQUIPMENTS_TABLE_COLUMN_LABELS = {
  jdE_EQUIPMENT_TAG: "Equipment Tag",
  manufacturer: "Manufacturer",
  maN_SERIAL_NUMBER: "Serial Number",
  pid: "PID",
  class: "Equipment Sub.Class",
  dcaM_STATUS_NAME: "Equipment Status",
  equipmenT_DESCRIPTION: "Equipment Description",
  aC_RANKING: "Equipment Criticality",
  syS_CODE: "System Group",
  planneR_GROUP: "Planner Group",
  planninG_PLANT: "Branch/Plant",
  planT_SECTION: "Plant Section",
  starT_UP_DATE: "Installation Date",
  status: "Status",
  constructioN_TYPE: "Construction Type",
  location: "Plant/Facility",
  constructioN_YEAR_MONTH: "Construction Year Month",
  iC_RANKING: "IC Ranking",
  details: "Details",
};

export const EQUIPMENT_COLUMNS_BY_USER_ROLE = {
  DEFAULT_USER: [
    {
      field: "jdE_EQUIPMENT_TAG",
      headerName: "Equipment Tag",
      width: 150,
      Visible: 1,
      order: 1,
      isChecked: true,
      isDisabled: true,
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: 100,
      Visible: 1,
      order: 2,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "maN_SERIAL_NUMBER",
      headerName: "Serial Number",
      width: 100,
      Visible: 1,
      order: 3,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "pid",
      headerName: "PID",
      width: 100,
      Visible: 1,
      order: 4,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "class",
      headerName: "Equipment Sub.Class",
      width: 100,
      Visible: 1,
      order: 5,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "dcaM_STATUS_NAME",
      headerName: "Equipment Status",
      width: 150,
      Visible: 1,
      order: 6,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "equipmenT_DESCRIPTION",
      headerName: "Equipment Description",
      width: 200,
      Visible: 1,
      order: 7,
      isChecked: true,
      isDisabled: true,
    },
    {
      field: "aC_RANKING",
      headerName: "Equipment Criticality",
      width: 100,
      Visible: 1,
      order: 8,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "syS_CODE",
      headerName: "System Group",
      width: 100,
      Visible: 1,
      order: 9,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "planneR_GROUP",
      headerName: "Planner Group",
      width: 100,
      Visible: 1,
      order: 10,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "planninG_PLANT",
      headerName: "Branch/Plant",
      width: 100,
      Visible: 1,
      order: 11,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "planT_SECTION",
      headerName: "Plant Section",
      width: 100,
      Visible: 1,
      order: 12,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "starT_UP_DATE",
      headerName: "Installation Date",
      width: 100,
      Visible: 1,
      order: 13,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      Visible: 1,
      order: 14,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "constructioN_TYPE",
      headerName: "Construction Type",
      width: 100,
      Visible: 1,
      order: 15,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "location",
      headerName: "Plant/Facility",
      width: 100,
      Visible: 1,
      order: 16,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "constructioN_YEAR_MONTH",
      headerName: "Construction Year Month",
      width: 100,
      Visible: 1,
      order: 17,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "iC_RANKING",
      headerName: "IC Ranking",
      width: 100,
      Visible: 1,
      order: 18,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "details",
      headerName: "Details",
      width: 100,
      Visible: 0,
      order: 100,
      isChecked: true,
      isDisabled: true,
    },
  ],
  SME_USER: [
    {
      field: "jdE_EQUIPMENT_TAG",
      headerName: "Equipment Tag",
      width: 150,
      Visible: 1,
      order: 1,
      isChecked: true,
      isDisabled: true,
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: 100,
      Visible: 1,
      order: 2,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "maN_SERIAL_NUMBER",
      headerName: "Serial Number",
      width: 100,
      Visible: 1,
      order: 3,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "pid",
      headerName: "PID",
      width: 100,
      Visible: 1,
      order: 4,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "class",
      headerName: "Equipment Sub.Class",
      width: 100,
      Visible: 1,
      order: 5,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "dcaM_STATUS_NAME",
      headerName: "Equipment Status",
      width: 150,
      Visible: 1,
      order: 6,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "equipmenT_DESCRIPTION",
      headerName: "Equipment Description",
      width: 200,
      Visible: 1,
      order: 7,
      isChecked: true,
      isDisabled: true,
    },
    {
      field: "aC_RANKING",
      headerName: "Equipment Criticality",
      width: 100,
      Visible: 1,
      order: 8,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "syS_CODE",
      headerName: "System Group",
      width: 100,
      Visible: 1,
      order: 9,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "planneR_GROUP",
      headerName: "Planner Group",
      width: 100,
      Visible: 1,
      order: 10,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "planninG_PLANT",
      headerName: "Branch/Plant",
      width: 100,
      Visible: 1,
      order: 11,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "planT_SECTION",
      headerName: "Plant Section",
      width: 100,
      Visible: 1,
      order: 12,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "starT_UP_DATE",
      headerName: "Installation Date",
      width: 100,
      Visible: 1,
      order: 13,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      Visible: 1,
      order: 14,
      isChecked: true,
      isDisabled: false,
    },
    {
      field: "constructioN_TYPE",
      headerName: "Construction Type",
      width: 100,
      Visible: 1,
      order: 15,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "location",
      headerName: "Plant/Facility",
      width: 100,
      Visible: 1,
      order: 16,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "constructioN_YEAR_MONTH",
      headerName: "Construction Year Month",
      width: 100,
      Visible: 1,
      order: 17,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "iC_RANKING",
      headerName: "IC Ranking",
      width: 100,
      Visible: 1,
      order: 18,
      isChecked: false,
      isDisabled: false,
    },
    {
      field: "details",
      headerName: "Details",
      width: 100,
      Visible: 0,
      order: 100,
      isChecked: true,
      isDisabled: true,
    },
  ],
};

export const COLUMN_FIELD = {
  JDE_EQUIPMENT_TAG: "jdE_EQUIPMENT_TAG",
  MANUFACTURER: "manufacturer",
  MAN_SERIAL_NUMBER: "maN_SERIAL_NUMBER",
  PID: "pid",
  CLASS: "class",
  DCAM_STATUS_NAME: "dcaM_STATUS_NAME",
  EQUIPMENT_DESCRTIPTION: "equipmenT_DESCRIPTION",
  AC_RANKING: "aC_RANKING",
  SYS_CODE: "syS_CODE",
  LOCATION: "location",
  CONSTRUCTION_YEAR_MONTH: "constructioN_YEAR_MONTH",
  IC_RANKING: "iC_RANKING",
  PLANNER_GROUP: "planneR_GROUP",
  PLANNING_PLANT: "planninG_PLANT",
  PLANT_SECTION: "planT_SECTION",
  START_UP_DATE: "starT_UP_DATE",
  STATUS: "status",
  CONSTRUCTION_TYPE: "constructioN_TYPE",
  DETAILS: "details",
};

export const EQUIPMENT_STATUS = {
  TAG_IS_NOT_SCHEDULED: "tag is not scheduled",
  TAG_IS_SCHEDULED: "tag is scheduled",
  TAG_DOES_NOT_EXIST: "tag does not exist",
};

export const MAX_ENABLED_COLUMNS_LIMIT = 8;

export const STORAGE_ACCOUNT_SAS_TOKEN =
  "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-01-11T17:23:23Z&st=2024-01-11T09:23:23Z&spr=https&sig=Kflo%2B9jBtVsHrcT%2FxAYlgYlz5mZZfO%2B3SjKXFcEN3LQ%3D";

export const STORAGE_ACCOUNT_NAME = "tcodcam332prodcvx";

export const MANUFACTURER_STATUS = {
  NEW: "New",
  JDE_E1_ORIGINAL: "JDE E1 original",
};
