import { Fragment, useEffect, memo } from "react";
import { green } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Grid,
  Paper,
  IconButton,
  CircularProgress,
  TableContainer,
  TablePagination,
} from "@mui/material";
import {
  getEquipmentsCurrentPage,
  setEquipmentsCurrentPage,
  getEquipmentsParams,
} from "../../store/slices/equipmentsFilterSlice";
import { useUserRole } from "../app/hooks/useUserRole";
import { useEquipment } from "../app/hooks/useEquipment";
import { CheckCircleOutline } from "@mui/icons-material";
import { TablePaginationActions } from "./TablePaginationActions";

export const EquipmentsTable = memo(
  ({
    equipmentState = [],
    setEquipmentState,
    columns,
    setColumns,
    dataCount,
    setDataCount,
    isLoading,
  }) => {
    const dispatch = useDispatch();
    const equipmentsCurrentPage = useSelector(getEquipmentsCurrentPage);
    const equipmentsParams = useSelector(getEquipmentsParams);
    const { getEquipment } = useEquipment();
    const { equipmentColumnsByRole, userRole } = useUserRole();
    const navigate = useNavigate();

    useEffect(() => {}, [equipmentsParams]);

    useEffect(() => {
      const storedColumns = localStorage.getItem(
        `selectedColumnsBy${userRole}`
      );
      const storedFilteredColumns = localStorage.getItem(
        `filterColumnsListBy${userRole}`
      );
      if (storedColumns) {
        const parsedFilteredColumns = JSON.parse(storedFilteredColumns);
        const updatedColumns = equipmentColumnsByRole.map((column) => {
          const storedColumn = parsedFilteredColumns.find(
            (stored) => stored.field === column.field
          );
          return storedColumn
            ? { ...column, isChecked: storedColumn.isChecked }
            : column;
        });
        setColumns(updatedColumns);
      } else {
        setColumns(equipmentColumnsByRole);
      }
    }, [equipmentColumnsByRole, setColumns, userRole]);

    const handleClickDetails = (value) => {
      const currentUrl = window.location.href;
      const urlParts = currentUrl.split("#");
      localStorage.setItem("equipmentUrl", urlParts[1]);
      navigate(`/Characteristics/${value}`);
    };

    async function fetchRows(startFrom, fetchRecord) {
      const data = await getEquipment({
        ...equipmentsParams,
        StartFrom: startFrom,
        FetchRecord: fetchRecord,
      });

      setEquipmentState(data.items);
      setDataCount(data.count);
    }

    const handleChangePage = (_event, page) => {
      const rowsPerPage = 25;
      const startFrom = rowsPerPage * page;
      fetchRows(startFrom, rowsPerPage);
      dispatch(setEquipmentsCurrentPage(page));
    };

    return (
      <Grid style={{ height: "100%" }} item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            minHeight: "400px",
            position: "relative",
          }}
        >
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              display: "table",
              tableLayout: "fixed",
              minHeight: "320px",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        color: "black",
                        fontWeight: "bold",
                      },
                    }}
                    key={columns}
                  >
                    {columns
                      .filter((col) => col.isChecked)
                      .map((col) => (
                        <TableCell key={col.headerName}>
                          {col.headerName}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ opacity: isLoading ? 0.5 : 1 }}>
                  {equipmentState.length
                    ? equipmentState.map((row) => (
                        <TableRow
                          sx={{
                            fontSize: "0.8rem",
                            color: "rgba(96, 96, 96)",
                            "& > *": {
                              padding: "0px",
                            },
                          }}
                          hover={true}
                          key={row.jdE_EQUIPMENT_TAG}
                        >
                          {columns
                            .filter((col) => col.isChecked)
                            .map((col) => {
                              return (
                                <Fragment key={col.headerName}>
                                  {col.field === "details" ? (
                                    <TableCell
                                      onClick={() =>
                                        handleClickDetails(
                                          row.jdE_EQUIPMENT_TAG
                                        )
                                      }
                                    >
                                      <IconButton
                                        sx={{ p: "10px", color: green[500] }}
                                        aria-label="menu"
                                      >
                                        <CheckCircleOutline />
                                      </IconButton>
                                      Detail
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      className="text-center"
                                      sx={{ padding: "0 15px" }}
                                    >
                                      {row[col.field]}
                                    </TableCell>
                                  )}
                                </Fragment>
                              );
                            })}
                        </TableRow>
                      ))
                    : null}
                  {!equipmentState.length && !isLoading && (
                    <TableRow>
                      <TableCell
                        colSpan={columns.filter((col) => col.isChecked).length}
                      >
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {equipmentState.length > 0 ? (
            <TablePagination
              component="div"
              rowsPerPageOptions={[25]}
              colSpan={3}
              count={dataCount}
              rowsPerPage={25}
              page={equipmentsCurrentPage}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          ) : null}
        </Paper>
      </Grid>
    );
  }
);
