import { useCallback, useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { SCOPE } from "../../../constants";

export const useMaterialsManagementData = () => {
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);

  const acquireAccessToken = useCallback(async () => {
    if (accounts.length === 0) {
      throw new Error("User is not signed in");
    }
    const request = {
      scopes: [SCOPE],
      account: accounts[0],
    };
    const authResult = await instance.acquireTokenSilent(request);
    return authResult.accessToken;
  }, [accounts, instance]);

  const getMaterialsManagementData = async (recordsCount, pageNumber) => {
    setLoading(true);
    try {
      const accessToken = await acquireAccessToken();
      const response = await axios.get(
        `${process.env.REACT_APP_APIBaseURL}/api/MaterialsManagement/GetMaterialsManagementData/${recordsCount}/${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log("ERROR:", error);
    }
  };

  return {
    loading,
    getMaterialsManagementData,
  };
};
