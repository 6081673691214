import React, { memo, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Toolbar, AppBar as MuiAppBar, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useUserData } from "../app/hooks/useUserData";
import { useDispatch, useSelector } from "react-redux";
import { setUserRole, selectUserRole } from "../../store/slices/userSlice";
import { resetFilter } from "../../store/slices/equipmentsFilterSlice";
import { USER_ROLES_ID } from "../../constants";

const styles = {
  root: {
    flexGrow: 1,
  },
  appbar: {
    alignItems: "center",
  },
};

let classes = {
  logo: {
    margin: "auto",
    textAlign: "center",
    maxWidth: "50%",
    maxHeight: "70%",
  },
};

export const AppBar = memo(() => {
  const dispatch = useDispatch();
  const { getUserRole } = useUserData();
  const userRole = useSelector(selectUserRole);
  const { accounts } = useMsal();
  const userAccountName = useMemo(() => accounts[0]?.username, [accounts]);

  useEffect(() => {
    getUserRole().then((user) => {
      dispatch(setUserRole(USER_ROLES_ID[user.id]));
    });
  }, []);

  const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  const drawerWidth = 150;

  const goBackToMainPage = () => {
    dispatch(resetFilter());
  };

  return (
    <StyledAppBar
      className={styles.appbar}
      position="relative"
      sx={{
        backgroundColor: "#009DD9",
        height: "60px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
        justifyContent: "center",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
        style={{ padding: "16px" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            padding: 0,
          }}
          onClick={goBackToMainPage}
        >
          <img
            height={55}
            width={55}
            style={{ marginRight: "10px" }}
            src={"../TCO.png"}
            className={classes.logo}
            alt="logo"
          />
          <Box
            variant="h6"
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 500,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link to="/">TCO Digital Core Asset Management</Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Box>{userAccountName.toLowerCase()}</Box>
          <Typography style={{ fontSize: "10px", fontWeight: 600 }}>
            {userRole}
          </Typography>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
});
