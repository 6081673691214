import { useEquipment } from "../app/hooks/useEquipment";
import { CircularProgress } from "@mui/material";
import { ImageGallery } from "../ImageGallery";

export const ScheduleImagesRender = ({
  images = [],
  isEditModeEnabled,
  onRemoveImage,
}) => {
  const { loading } = useEquipment();

  let content;
  if (loading) {
    content = <CircularProgress size={20} />;
  } else if (images.length) {
    content = (
      <ImageGallery
        style={{ width: 200, height: "auto" }}
        withDescription={false}
        cols={5}
        rowHeight={30}
        gap={10}
        imageList={images || []}
        isEditModeEnabled={isEditModeEnabled}
        onRemoveImage={onRemoveImage}
      />
    );
  } else {
    content = "No photos yet";
  }

  return <>{content}</>;
};
