import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { setUserRoleLoading } from "../../../store/slices/userSlice";
import { RESOURCE_ID } from "../../../constants";

export const useUserData = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const acquireAccessToken = async () => {
    if (accounts.length === 0) {
      throw new Error("User is not signed in");
    }
    const request = {
      scopes: ["https://graph.microsoft.com/User.Read"],
      account: accounts[0],
    };

    const authResult = await instance.acquireTokenSilent(request);

    return authResult.accessToken;
  };

  const getAppRoleId = async (accessToken) => {
    const response = await axios.get(
      `https://graph.microsoft.com/v1.0/me/appRoleAssignments`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          $filter: `resourceId eq ${RESOURCE_ID}`,
        },
      }
    );

    return response.data.value[0].appRoleId;
  };

  const getAppRoles = async (accessToken) => {
    const rolesResponse = await axios.get(
      `https://graph.microsoft.com/v1.0/servicePrincipals/${RESOURCE_ID}?$select=appRoles`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return rolesResponse.data.appRoles;
  };

  const getUserRole = async () => {
    try {
      dispatch(setUserRoleLoading(true));
      const accessToken = await acquireAccessToken();
      const appRoleId = await getAppRoleId(accessToken);
      const appRoles = await getAppRoles(accessToken);
      const userRoleData = appRoles.filter((role) => role.id === appRoleId)[0];
      dispatch(setUserRoleLoading(false));
      return userRoleData;
    } catch (error) {
      dispatch(setUserRoleLoading(false));
      console.error("Error fetching user role:", error);
      throw error;
    }
  };

  return {
    getUserRole,
  };
};
