import { memo } from "react";
import {
  getEquipmentsParams,
  setEquipmentsParams,
} from "../../store/slices/equipmentsFilterSlice";
import { useSelector, useDispatch } from "react-redux";
import { Box, Paper, IconButton, InputBase, Button } from "@mui/material";
import { Search } from "@mui/icons-material";

export const SearchComponent = memo(({ searchValue, setSearchValue }) => {
  const equipmentsParams = useSelector(getEquipmentsParams);
  const dispatch = useDispatch();

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleEnterKeydown = (event) => {
    const value = event.target.value;
    if (!value.length) return;
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchSubmit(event, "enter");
    }
  };

  const handleSearchSubmit = (event, type) => {
    event.preventDefault();
    let value = "";
    if (type === "search") {
      value = event.target[1].value;
    } else {
      value = event.target.value;
    }
    const tagSearchValue = `jdE_EQUIPMENT_TAG,Contains,${value}`;

    const getFilteredColums = () => {
      if (equipmentsParams.FilteredColums) {
        const uniqueFilterValues = Array.from(
          new Set(
            (equipmentsParams.FilteredColums + ";" + tagSearchValue).split(";")
          )
        ).join(";");
        return uniqueFilterValues;
      } else {
        return tagSearchValue;
      }
    };

    dispatch(
      setEquipmentsParams({
        ...equipmentsParams,
        Search: value,
        FilteredColums: getFilteredColums(),
      })
    );
  };

  return (
    <Box
      component="form"
      onKeyDown={handleEnterKeydown}
      onSubmit={(event) => handleSearchSubmit(event, "search")}
      style={{ display: "flex", alignItems: "center", width: "100%" }}
    >
      <Paper
        sx={{
          p: "2px",
          display: "flex",
          alignItems: "center",
          width: 450,
        }}
      >
        <IconButton aria-label="search">
          <Search style={{ fontSize: "20px", display: "flex" }} />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Equipment Tags"
          inputProps={{ "aria-label": "search equipment tags" }}
          value={searchValue}
          onChange={handleSearchValueChange}
        />
      </Paper>
      <Button
        style={{
          width: "108px",
          padding: "8px 16px",
          marginLeft: "12px",
        }}
        variant="contained"
        type="submit"
      >
        Search
      </Button>
    </Box>
  );
});
