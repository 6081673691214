import { useState, useEffect, useCallback, memo } from "react";
import {
  Paper,
  Autocomplete,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  setEquipmentsParams,
  getEquipmentsParams,
  setEquipmentsCurrentPage,
} from "../../store/slices/equipmentsFilterSlice";
import { Search, ListAlt } from "@mui/icons-material";
import { useEquipment } from "../app/hooks/useEquipment";
import { useDispatch, useSelector } from "react-redux";

export const AreaAndUnitSection = memo(
  ({
    areaCode,
    setAreaCode,
    unitCode,
    setUnitCode,
    equipmentState = [],
    isLoading,
  }) => {
    const dispatch = useDispatch();
    const equipmentsParams = useSelector(getEquipmentsParams);
    const [areaCodesList, setAreaCodesList] = useState([]);
    const { getEquipmentsAreaCodes, isAreaCodeLoading } = useEquipment();

    const transformAreaCodeData = (data) => {
      return data.reduce((result, row) => {
        let category = result.find((item) => item.id === row.feareaCode);

        if (!category) {
          category = {
            id: row.feareaCode,
            label: row.feareaCode,
            children: [],
          };
          result.push(category);
        }

        let objectType = category.children.find(
          (item) => item.feunitCode === row.feunitCode
        );

        if (!objectType) {
          objectType = {
            id: row.feunitCode,
            label: row.feunitCode,
            children: [],
          };
          category.children.push(objectType);
        }

        return result;
      }, []);
    };

    const getAreaCodesState = useCallback(async () => {
      const data = await getEquipmentsAreaCodes();
      setAreaCodesList(transformAreaCodeData(data));
    }, [getEquipmentsAreaCodes]);

    const handleAreaCodeChange = (_event, value) => {
      dispatch(setEquipmentsCurrentPage(0));
      setAreaCode(value);
      if (!value) {
        setUnitCode(null);
      }
      dispatch(
        setEquipmentsParams({
          ...equipmentsParams,
          AreaCode: value,
          UnitCode: undefined,
        })
      );
    };

    useEffect(() => {
      getAreaCodesState();
    }, []);

    const handleUnitCodeChange = (_event, value) => {
      dispatch(setEquipmentsCurrentPage(0));
      setUnitCode(value);
      dispatch(
        setEquipmentsParams({
          ...equipmentsParams,
          UnitCode: value,
        })
      );
    };

    return (
      <Paper
        sx={{
          padding: "18px 24px",
          display: "flex",
          height: "auto",
          marginTop: "24px",
          marginBottom: "20px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Autocomplete
            loading={isAreaCodeLoading}
            disabled={!equipmentState.length && isLoading}
            disablePortal
            id="areaCode"
            size="small"
            options={areaCodesList.map((item) => item.label)}
            onChange={handleAreaCodeChange}
            value={areaCode || null}
            sx={{ width: "200px", marginRight: "24px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Area Code"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <ListAlt />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Autocomplete
            disabled={!areaCode || isLoading}
            loading={isAreaCodeLoading}
            disablePortal
            id="UnitCode"
            size="small"
            value={unitCode || null}
            options={
              areaCode
                ? areaCodesList
                    .find((item) => item.id === areaCode)
                    ?.children.map((item) => item.label)
                : [{ label: "" }]
            }
            onChange={handleUnitCodeChange}
            sx={{ width: "200px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Unit Code"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Paper>
    );
  }
);
