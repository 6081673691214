import { SCOPE } from "../constants";
export const msalConfig = {
  auth: {
    clientId: "aed0a4f9-2df6-4782-afb3-98dd719fc0a2",
    authority:
      "https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
    redirectUri: window.location.origin + window.location.pathname,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: [SCOPE],
};
