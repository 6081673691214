import { useState } from "react";
import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { displayFormattedDate } from "../app/utils/displayFormattedDate";
import {
  STORAGE_ACCOUNT_SAS_TOKEN,
  STORAGE_ACCOUNT_NAME,
} from "../../constants";
import "./ImageGallery.css";
import { ImageLightBoxGallery } from "../ImageLightBoxGallery/ImageLightBoxGallery";
import RemoveIcon from "@mui/icons-material/Cancel";
import { ConfirmationDialog } from "../Dashboard/ConfirmationDialog";

const imgTxtStyle = {
  color: "orange",
  marginRight: "6px",
};

export const ImageGallery = ({
  imageList = [],
  cols,
  gap,
  rowHeight,
  style,
  withDescription,
  isEditModeEnabled,
  onRemoveImage,
}) => {
  const [images, setImages] = useState(imageList);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentImageId, setCurrentImageId] = useState(null);

  const handleImageClick = (index) => {
    setCurrentImageIndex(() => index);
    setIsImageOpen(true);
  };

  const setImageLink = (imgURL) => {
    if (imgURL) {
      if (imgURL.includes(STORAGE_ACCOUNT_NAME)) {
        return `${imgURL}${STORAGE_ACCOUNT_SAS_TOKEN}`;
      }
      return imgURL;
    }
  };

  function getImageTextValue(label, value) {
    return (
      <div>
        <span style={imgTxtStyle}>{label}</span>
        {value ?? "N/A"}
      </div>
    );
  }

  function displayRemoveIcon(imageId) {
    if (!withDescription) {
      return (
        <RemoveIcon
          color="error"
          fontSize="14px"
          sx={{ position: "absolute", bottom: 24, left: 24 }}
          onClick={(event) => handleImageRemove(event, imageId)}
        />
      );
    } else {
      return (
        <RemoveIcon
          color="error"
          className="remove-icon"
          onClick={(event) => handleImageRemove(event, imageId)}
        />
      );
    }
  }

  function displayDescription(item) {
    return (
      <div style={{ whiteSpace: "normal" }}>
        <div>
          <span style={imgTxtStyle}>Created on:</span>
          {displayFormattedDate(item.createdDate)}
        </div>
        {getImageTextValue("Model:", item.equipmentModel)}
        {getImageTextValue("Manufacturer:", item.equipmentManufacturer)}
        {getImageTextValue("Serial Number:", item.equipmentSerialNo)}
        {getImageTextValue("Comments:", item.comment)}
        {getImageTextValue(
          "Operational Status:",
          item.equipmentOperationalStatus
        )}
        {getImageTextValue("Image Status:", item.equipmentImageStatus)}
        <div>
          <span style={imgTxtStyle}>Completed on:</span>
          {displayFormattedDate(item?.completedDate)}
        </div>
        {getImageTextValue("Creator ID:", item.createdUserId)}
      </div>
    );
  }

  const handleImageRemove = (event, imageId) => {
    event.stopPropagation();
    setCurrentImageId(imageId);
    setIsDialogOpen(true);
  };

  const handleImageDeleteSubmit = () => {
    const updatedImagesList = images.filter(
      (image) => image.equipmentImageId !== currentImageId
    );
    setImages(updatedImagesList);
    onRemoveImage(currentImageId);
    setIsDialogOpen(false);
  };

  const handleClose = () => {
    setCurrentImageId(null);
    setIsDialogOpen(false);
  };

  return (
    <>
      {images.length ? (
        <ImageList
          sx={style}
          cols={cols}
          gap={gap}
          rowHeight={rowHeight}
          className="image-list"
        >
          {images.map((item, index) => (
            <ImageListItem
              className="image-item"
              key={`${item.imageLink}_${index}`}
              onClick={() => handleImageClick(index)}
            >
              <img
                src={`${setImageLink(item.imageLink)}`}
                alt="Equipment"
                style={{
                  height: `${rowHeight}px`,
                  maxWidth: "100%",
                  display: "block",
                }}
                loading="lazy"
              />
              {isEditModeEnabled && displayRemoveIcon(item.equipmentImageId)}
              {withDescription ? (
                <ImageListItemBar
                  title={item.imageDescription}
                  subtitle={displayDescription(item)}
                />
              ) : null}
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        "No photos yet"
      )}
      <ImageLightBoxGallery
        key={currentImageIndex}
        isImageOpen={isImageOpen}
        setIsImageOpen={setIsImageOpen}
        images={images.map((image) => setImageLink(image.imageLink))}
        index={currentImageIndex}
      />
      <ConfirmationDialog
        title="Do you want to remove this image?"
        subtitle="The image will be deleted forever after saving changes."
        setIsOpen={setIsDialogOpen}
        isOpen={isDialogOpen}
        handleSubmit={handleImageDeleteSubmit}
        handleClose={handleClose}
      />
    </>
  );
};
