import ReactDOM from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./app/authConfig";
import { App } from "../src/components/app/App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import "../src/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <StoreProvider store={store}>
    <Router>
      <MsalProvider instance={msalInstance}>
        <App />
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
        />
      </MsalProvider>
    </Router>
  </StoreProvider>
);

reportWebVitals();
