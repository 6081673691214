export const displayFormattedDate = (date) => {
  if (date) {
    const createdDate = new Date(date);
    const day = createdDate.getDate().toString().padStart(2, "0");
    const month = (createdDate.getMonth() + 1).toString().padStart(2, "0");
    const year = createdDate.getFullYear();
    return `${day}.${month}.${year}`;
  }
  return "N/A";
};
