import React, { useEffect, useState, memo, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import {
  setEquipmentsParams,
  getEquipmentsParams,
  setEquipmentCategories,
} from "../../store/slices/equipmentsFilterSlice";
import { useDispatch, useSelector } from "react-redux";
import { SCOPE } from "../../constants";

export const Categories = memo(() => {
  const dispatch = useDispatch();
  const equipmentsCurrentParams = useSelector(getEquipmentsParams);
  const { instance, accounts } = useMsal();
  const [selectedCategory, setSelectedCategory] = useState(() => {
    const cachedData = localStorage.getItem("selectedCategory");
    return cachedData ? JSON.parse(cachedData) : [];
  });

  const acquireAccessToken = useCallback(async () => {
    if (accounts.length === 0) {
      throw new Error("User is not signed in");
    }

    const request = {
      scopes: [SCOPE],
      account: accounts[0],
    };

    const authResult = await instance.acquireTokenSilent(request);
    return authResult.accessToken;
  }, [accounts, instance]);

  useEffect(() => {
    const loadData = async () => {
      const accessToken = await acquireAccessToken();

      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_APIBaseURL}/api/Categories/GetCategoriesList`,
          { headers }
        );

        dispatch(setEquipmentCategories(categoryResponse.data));

        const transformedCategoryData = transformCategoryData(
          categoryResponse.data
        );

        setSelectedCategory(transformedCategoryData);
        localStorage.setItem(
          "selectedCategory",
          JSON.stringify(transformedCategoryData)
        );
      } catch (error) {
        console.error(error);
      }
    };

    const transformCategoryData = (data) => {
      return data.reduce((result, row) => {
        let category = result.find((item) => item.id === row.catID);

        if (!category) {
          category = {
            id: row.catID,
            title: row.category,
            children: [],
          };
          result.push(category);
        }

        let objectType = category.children.find(
          (item) => item.id === row.objTypeID
        );

        if (!objectType) {
          objectType = {
            id: row.objTypeID,
            title: row.objectType,
            children: [],
          };
          category.children.push(objectType);
        }

        let classification = objectType.children.find(
          (item) => item.id === row.classID
        );

        if (!classification) {
          classification = {
            id: row.classID,
            title: row.classification,
            children: [],
          };
          objectType.children.push(classification);
        }

        return result;
      }, []);
    };

    loadData();
  }, [acquireAccessToken, dispatch]);

  function dispatchParamsByCategoryId(currentId) {
    const {
      StartFrom,
      FetchRecord,
      AreaCode,
      UnitCode,
      FilteredColums,
      Search,
    } = equipmentsCurrentParams; //.. do not spread inside dispatch, we need only exactly fields
    dispatch(
      setEquipmentsParams({
        Search,
        StartFrom,
        FetchRecord,
        AreaCode,
        UnitCode,
        CategoryId: currentId,
        FilteredColums,
      })
    );
  }

  function dispatchParamsByClassId(categoryId, currentId) {
    const {
      StartFrom,
      FetchRecord,
      AreaCode,
      UnitCode,
      FilteredColums,
      Search,
    } = equipmentsCurrentParams; //.. do not spread inside dispatch, we need only exactly fields
    dispatch(
      setEquipmentsParams({
        Search,
        StartFrom,
        FetchRecord,
        AreaCode,
        UnitCode,
        CategoryId: categoryId,
        ClassId: currentId,
        FilteredColums,
      })
    );
  }

  function dispatchParamsBySubclassId(categoryId, classId, currentId) {
    const {
      StartFrom,
      FetchRecord,
      AreaCode,
      UnitCode,
      FilteredColums,
      Search,
    } = equipmentsCurrentParams; //.. do not spread inside dispatch, we need only exactly fields
    dispatch(
      setEquipmentsParams({
        StartFrom,
        FetchRecord,
        AreaCode,
        UnitCode,
        CategoryId: categoryId,
        ClassId: classId,
        SubClassId: currentId,
        FilteredColums,
        Search,
      })
    );
  }

  const handleCategoryNodeClick = (currentId, parentIds, depth, event) => {
    event.preventDefault();
    const [categoryId, classId] = parentIds;
    switch (depth) {
      case 0:
        dispatchParamsByCategoryId(currentId);
        break;
      case 1:
        dispatchParamsByClassId(categoryId, currentId);
        break;
      case 2:
        dispatchParamsBySubclassId(categoryId, classId, currentId);
        break;
      default:
        return;
    }
  };

  const renderNewCategoriesList = (node, parentIds = [], depth = 0, index) => {
    return (
      <TreeItem
        key={`${node.id}_${index}_${node.title}`}
        nodeId={`${node.id}_${index}_${node.title}`}
        label={node.title}
        onClick={(event) =>
          handleCategoryNodeClick(node.id, parentIds, depth, event)
        }
      >
        {Array.isArray(node.children)
          ? node.children.map((nodeItem) =>
              renderNewCategoriesList(
                nodeItem,
                [...parentIds, node.id],
                depth + 1
              )
            )
          : null}
      </TreeItem>
    );
  };

  return (
    <>
      <Typography variant="h6" style={{ flexGrow: 1, paddingLeft: 15 }}>
        Categories
      </Typography>
      {selectedCategory && selectedCategory.length > 1 ? (
        <SimpleTreeView
          aria-label="categories navigator"
          sx={{
            height: "auto",
            flexGrow: 1,
            maxWidth: 400,
            overflowY: "auto",
          }}
        >
          {selectedCategory.map((category, index) =>
            renderNewCategoriesList(category, [], 0, index)
          )}
        </SimpleTreeView>
      ) : (
        <Typography>No categories to display.</Typography>
      )}
    </>
  );
});
