import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import SourceIcon from "@mui/icons-material/Source";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Link, useNavigate } from "react-router-dom";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Tooltip } from "@mui/material";

const WorkWithDataListItem = () => {
  const navigate = useNavigate(); // Use useNavigate here

  const handleWorkWithData = () => {
    navigate("/equipments"); // Navigate to the specified URL
  };

  return (
    <ListItemButton onClick={handleWorkWithData}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Work with Data" />
    </ListItemButton>
  );
};
export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <Tooltip title="Walkdown Schedules">
        <ListItemIcon>
          <ScheduleIcon />
        </ListItemIcon>
      </Tooltip>
      <Link primary="WalkdownSchedules" to="/WalkdownSchedules">
        Walkdown Schedules
      </Link>

      {/* <ListItemText primary="Dashboard" /> */}
    </ListItemButton>
    <ListItemButton>
      <Tooltip title="Walkdown Users">
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
      </Tooltip>
      <Link primary="Walkdownusers" to="/Walkdownusers">
        Walkdown Users
      </Link>
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset></ListSubheader>
    <WorkWithDataListItem></WorkWithDataListItem>
    <ListItemButton>
      <Tooltip title="Monitor">
        <ListItemIcon>
          <ShowChartIcon />
        </ListItemIcon>
      </Tooltip>
      <Link primary="Monitor" to="/PowerBI">
        Monitor
      </Link>
    </ListItemButton>
    <ListItemButton>
      <Tooltip title="Walkdown Management">
        <ListItemIcon>
          <ScheduleIcon />
        </ListItemIcon>
      </Tooltip>
      <Link primary="Walkdownusers" to="/WalkdownSchedules">
        Walkdown Management
      </Link>
    </ListItemButton>
  </React.Fragment>
);
