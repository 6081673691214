import { createTheme, ThemeProvider } from "@mui/material/styles";
import Main from "./mainDash";

const mdTheme = createTheme();

export const Dashboard = () => {
  return (
    <ThemeProvider theme={mdTheme}>
      <Main />
    </ThemeProvider>
  );
};
