import { Fragment, useState, useEffect, memo } from "react";
import { Popover, CardHeader, Divider, Box } from "@mui/material";
import { useUserRole } from "../app/hooks/useUserRole";

export const FilterByColumns = memo(
  ({ listData, setAnchorElColumn, anchorElColumn, handleSelectedColumn }) => {
    const { userRole, equipmentColumnsByRole } = useUserRole();
    const filteredList = listData.filter((item) => item.Visible);
    const LOCAL_STORAGE_KEY = `filterColumnsListBy${userRole}`;
    const [items, setItems] = useState(filteredList);
    const openColumn = Boolean(anchorElColumn);
    const idColumn = openColumn ? "simple-popover" : undefined;

    useEffect(() => {
      const storedItems = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (storedItems) {
        const parsedItems = JSON.parse(storedItems);
        const updatedColumns = equipmentColumnsByRole.map((column) => {
          const storedColumn = parsedItems.find(
            (stored) => stored.field === column.field
          );
          return storedColumn
            ? { ...column, isChecked: storedColumn.isChecked }
            : column;
        });
        setItems(updatedColumns);
      } else {
        setItems(equipmentColumnsByRole);
      }
    }, [LOCAL_STORAGE_KEY, equipmentColumnsByRole]);

    const handleCloseColumnsDialog = () => {
      setAnchorElColumn(null);
    };

    const handleColumnSwitch = (event) => {
      const itemName = event.target.name;
      const isChecked = event.target.checked;
      const updatedItems = items.map((item) => {
        if (item.headerName === itemName) {
          return { ...item, isChecked };
        } else {
          return item;
        }
      });
      const checkedCount = items.reduce(
        (count, item) => count + (item.isChecked ? 1 : 0),
        0
      );
      if (isChecked && checkedCount >= 9) {
        console.log("Not allowed to check more than 9 items");
        handleSelectedColumn(event);
        return;
      }
      setItems(updatedItems);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedItems));
      handleSelectedColumn(event);
    };

    return (
      <Popover
        id={idColumn}
        open={openColumn}
        anchorEl={anchorElColumn}
        onClose={handleCloseColumnsDialog}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <CardHeader title="Columns" />
        <Divider></Divider>
        {items.map((item, index) => {
          return (
            <Fragment key={index}>
              <Box p={1} width={250} key={item.id}>
                <label className="switch">
                  <input
                    type="checkbox"
                    name={item.headerName}
                    value={item.field}
                    disabled={item.isDisabled}
                    checked={item.isChecked}
                    dataorder={item.order} // add data-order attribute to store the order
                    onChange={handleColumnSwitch}
                  />
                  <span className="slider round"></span>
                </label>
                <label style={{ marginLeft: 10 }}>{item.headerName}</label>
              </Box>
              <Divider />
            </Fragment>
          );
        })}
      </Popover>
    );
  }
);
