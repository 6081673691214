import React, { useCallback, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  List,
  Divider,
  Drawer as MUIDrawer,
  useMediaQuery,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SourceIcon from "@mui/icons-material/Source";
import { styled, createTheme } from "@mui/material/styles";
import { mainListItems, secondaryListItems } from "./listItems";
import { Categories } from "./Categories";

const mdTheme = createTheme();

const StyledDrawer = styled(MUIDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    zIndex: 999,
    position: "relative",
    whiteSpace: "nowrap",
    width: 300,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const Drawer = ({ isMMBtnEnabled }) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const isSmallScreen = useMediaQuery(mdTheme.breakpoints.down("sm"));

  const getCurrentComponent = useCallback(() => {
    const currentComponent = location.pathname;
    if (
      currentComponent === "/WalkdownSchedules" ||
      currentComponent === "/Walkdownusers" ||
      currentComponent === "/WalkdownScheduleEquipment" ||
      currentComponent === "/WalkdownScheduleEquipment/Id"
    ) {
      return mainListItems;
    } else if (currentComponent === "/" || currentComponent === "/PowerBI") {
      return (
        <>
          {secondaryListItems}
          {isMMBtnEnabled && (
            <ListItemButton>
              <Tooltip title="Materials Management">
                <ListItemIcon>
                  <SourceIcon />
                </ListItemIcon>
              </Tooltip>
              <Link primary="MaterialsManagement" to="/MaterialsManagement">
                Materials Management
              </Link>
            </ListItemButton>
          )}
        </>
      );
    } else if (currentComponent.startsWith("/WalkdownScheduleEquipment")) {
      return mainListItems;
    } else {
      return <Categories />;
    }
  }, [location.pathname]);

  useEffect(() => {
    getCurrentComponent();
  }, [getCurrentComponent, location.pathname]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <StyledDrawer
      variant={isSmallScreen ? "temporary" : "permanent"}
      open={open}
    >
      {isSmallScreen && (
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      )}
      <List component="nav">
        {getCurrentComponent()}
        <Divider />
      </List>
    </StyledDrawer>
  );
};
