import { useEffect, useState } from "react";
import { VirtualizedAutocomplete } from "./VirtualizedAutocomplete";
import { OPERATOR } from "../../constants";
import {
  TableRow,
  TableCell,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setFilteredValuesByColumn } from "../../store/slices/equipmentsFilterSlice";
import { useEquipment } from "../app/hooks/useEquipment";
import { useUserRole } from "../app/hooks/useUserRole";
import { COLUMN_FIELD, EQUIPMENTS_TABLE_COLUMN_LABELS } from "../../constants";

export const FilterByColumnData = ({
  rowKey,
  rowIndex,
  filterRows,
  onFieldChange,
  setFilterRows,
  onValueChange,
  setIsResetClicked,
  isResetClicked,
  onOperatorChange,
  filterParamsByColumn,
  setFilterParamsByColumn,
}) => {
  const dispatch = useDispatch();
  const { equipmentColumnsByRole } = useUserRole();
  const columnsList = equipmentColumnsByRole.filter(
    (columns) =>
      columns.Visible && columns.field !== COLUMN_FIELD.DCAM_STATUS_NAME
  );
  const { getAllDataByColumn, loading } = useEquipment();
  const [searchColumnResult, setSearchColumnResult] = useState([]);
  const [searchColumn, setSearchColumn] = useState(
    EQUIPMENTS_TABLE_COLUMN_LABELS[filterParamsByColumn?.[rowKey]?.field] ||
      null
  );
  const [selectedOperator, setSelectedOperator] = useState(
    filterParamsByColumn?.[rowKey]?.operator || null
  );
  const [searchValue, setSearchValue] = useState(
    filterParamsByColumn?.[rowKey]?.value || null
  );

  useEffect(() => {
    if (searchColumn?.value) {
      getAllDataByColumn(searchColumn.value).then((data) => {
        setSearchColumnResult(
          data.map((item) => ({
            value: item.selectedColumnUniqueData,
            label: item.selectedColumnUniqueData,
          }))
        );
      });
    } else {
      setSearchColumnResult([]);
    }
  }, [getAllDataByColumn, searchColumn?.value]);

  useEffect(() => {
    if (isResetClicked) {
      setSearchColumn(null);
      setSelectedOperator(null);
      setSearchValue(null);
      setSearchColumnResult([]);
    }
  }, [isResetClicked]);

  const handleSearchColumnChange = (_event, option) => {
    setSearchColumnResult([]);
    setSearchValue(null);
    if (option) {
      setSearchColumn(option);
      onFieldChange(option.value, rowKey);
      setIsResetClicked(false);
    }
  };

  const handleOperatorChange = (_event, option) => {
    if (option) {
      setSelectedOperator(option);
      onOperatorChange(option.label, rowKey);
      setIsResetClicked(false);
    }
  };

  const handleOptionValueSelectChange = (_event, option) => {
    if (option) {
      setSearchValue(option);
      onValueChange(option.value, rowKey);
      setIsResetClicked(false);
    }
  };

  const handleInputValueChangeManually = (event) => {
    setIsResetClicked(false);
    if (event) {
      const inputValue = event.target?.value;
      if (inputValue || inputValue === " ") {
        setSearchValue({ value: inputValue, label: inputValue });
        onValueChange(inputValue, rowKey);
      }
    }
  };

  const updateFilteredValuesByColumn = (rowKey) => {
    if (filterParamsByColumn) {
      const updatedFilter = Object.keys(filterParamsByColumn)
        .filter((key) => Number(key) !== rowKey)
        .reduce((result, key) => {
          result[key] = filterParamsByColumn[key];
          return result;
        }, {});
      setFilterParamsByColumn(updatedFilter);
      dispatch(setFilteredValuesByColumn(updatedFilter));
    }
  };

  const handleRemoveSelectedFilter = (index, rowKey) => {
    const updatedFilters = [...filterRows];
    updatedFilters.splice(index, 1);
    setFilterRows(updatedFilters);
    setSearchColumn(null);
    setSelectedOperator(null);
    setSearchValue(null);
    if (searchColumn && selectedOperator && searchValue) {
      updateFilteredValuesByColumn(rowKey);
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Autocomplete
          options={columnsList.map(({ field, headerName }) => ({
            value: field,
            label: headerName,
          }))}
          renderInput={(params) => <TextField {...params} label="Column" />}
          onChange={handleSearchColumnChange}
          value={searchColumn}
          id={`filterColumnName_${rowKey}`}
          sx={{ width: 200 }}
          disableClearable
        />
      </TableCell>
      <TableCell align="right">
        <Autocomplete
          options={OPERATOR.map(({ field, headerName }) => ({
            value: field,
            label: headerName,
          }))}
          renderInput={(params) => <TextField {...params} label="Operator" />}
          onChange={handleOperatorChange}
          value={selectedOperator}
          id={`filterOperator_${rowKey}`}
          sx={{ width: 150 }}
          loading={loading}
          loadingText="Loading"
          disabled={!searchColumn}
          disableClearable
        />
      </TableCell>
      <TableCell align="right">
        <VirtualizedAutocomplete
          options={searchColumnResult}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          label="Value"
          loadingText="Loading..."
          id={`value_${rowKey}`}
          sx={{ width: 250 }}
          loading={loading}
          disabled={selectedOperator === null}
          freeSolo
          onChange={handleOptionValueSelectChange}
          onInputChange={handleInputValueChangeManually}
          isResetClicked={isResetClicked}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleRemoveSelectedFilter(rowIndex, rowKey)}
        >
          Remove
        </Button>
      </TableCell>
    </TableRow>
  );
};
