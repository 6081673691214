import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEquipment } from "../app/hooks/useEquipment";
import { Goback } from "./Goback";
import { useUserRole } from "../app/hooks/useUserRole";

export const Walkdownusers = () => {
  const { isAdmin, isUserRoleLoading, isWalkdownCoordinator } = useUserRole();
  const {
    loading,
    getWalkDownUserWithToken,
    handleSaveWalkdownUser,
    handleDeleteWalkdownUser,
  } = useEquipment();
  const [walkdownState, setWalkdownState] = useState([]);
  const [newUser, setNewUser] = useState({
    userPk: "",
    firstName: "",
    lastName: "",
    email: "",
    isAdmin: false,
    password: "",
  });
  const [lastNameError, setLastNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [emailError, setEmailError] = useState("");
  const validateEmail = (email) => {
    // Regular expression to validate email address
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  // Inside the dialog actions, call the `handleDeleteUser` function if the user confirms

  const [editMode, setEditMode] = useState(false);
  const [TextMode, setTextMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarVariant, setSnackbarVariant] = useState("");

  useEffect(() => {
    getWalkDownUser();
  }, []);

  async function getWalkDownUser() {
    const walkdownuser = await getWalkDownUserWithToken();
    setWalkdownState(walkdownuser);
  }

  const handleUpdateUser = (updatedUser, userPk) => {
    // Find the user by userId in the walkdownState array and update it
    // const updatedState = walkdownState.map((user) =>
    //   user.userId === updatedUser.userId ? updatedUser : user
    // );
    setWalkdownState((prevState) =>
      prevState.map((u) => (u.userPk === updatedUser.userPk ? updatedUser : u))
    );
    //setWalkdownState(updatedState);
    handleSaveWalkdownUser(updatedUser)
      .then((response) => {
        // User updated successfully
        // setSnackbarVariant("success");
        setSnackbarMessage("User updated successfully!");
        setSnackbarOpen(true);
        setLoading(false); // Hide the loader
      })
      .catch((error) => {
        // Failed to update user
        // setSnackbarVariant("error");
        setSnackbarMessage("Failed to update user.");
        setSnackbarOpen(true);
        console.error(error);
      });
  };

  const handleDeleteUser = (userPk) => {
    // Filter out the user with the specified userId from the walkdownState array
    // const updatedState = walkdownState.filter((user) => user.userId !== userId);

    // setWalkdownState(updatedState);

    handleDeleteWalkdownUser(userPk)
      .then((response) => {
        // User deleted successfully
        // setSnackbarVariant("success");
        setSnackbarMessage("User deleted successfully!");
        setSnackbarOpen(true);

        // Remove the deleted user from the walkdownState array
        setWalkdownState((prevState) =>
          prevState.filter((user) => user.userPk !== userPk)
        );
      })
      .catch((error) => {
        // Failed to delete user
        // setSnackbarVariant("error");
        setSnackbarMessage("Failed to delete user.");
        setSnackbarOpen(true);
        console.error(error);
      });
  };
  const handleAdd = () => {
    setEditMode(true);
    setTextMode(true);
    setNewUser({
      userPk: "",
      firstName: "",
      lastName: "",
      email: "",
      isAdmin: false,
      password: "",
    });
  };
  const handleEditUser = (user) => {
    setEditMode(true);
    setTextMode(false);
    setNewUser(user);
    console.log(user);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setNewUser({
      firstName: "",
      lastName: "",
      email: "",
      isAdmin: false,
      password: "",
      userPk: "",
    });
  };

  const handleSaveUser = () => {
    setLoading(true);
    if (!validateEmail(newUser.email)) {
      setEmailError("Invalid email address");
      setLoading(false);
      return;
    }
    handleSaveWalkdownUser(newUser)
      .then(() => {
        setSnackbarMessage("User saved successfully!");
        setSnackbarOpen(true);
        setLoading(false);
        setEditMode(false);
        getWalkDownUser();
      })
      .catch((error) => {
        setSnackbarMessage("Failed to save user.");
        setSnackbarOpen(true);
        console.error(error);
        setLoading(false);
      });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Goback></Goback>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" component="div" gutterBottom>
              Walkdown Users
            </Typography>
            <Box
              sx={{
                display: "flex ",
                justifyContent: "flex-end",
                marginBottom: 2,
              }}
            >
              {!editMode && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAdd}
                  disabled={
                    isUserRoleLoading || (!isAdmin && !isWalkdownCoordinator)
                  }
                >
                  Add User
                </Button>
              )}
            </Box>
          </Box>
          <Grid container justifyContent="center">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 400,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "table",
                    tableLayout: "fixed",
                    overflow: "auto",
                  }}
                >
                  <Table size="small" sx={{ minWidth: 600 }}>
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            color: "black", // Set color to black
                            fontWeight: "bold", // Set font weight to bold
                          },
                        }}
                      >
                        <TableCell>#</TableCell>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Password</TableCell>
                        <TableCell>Is Admin</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            <CircularProgress
                              sx={{ color: blue[500] }}
                              size={10}
                            />
                          </TableCell>
                        </TableRow>
                      ) : (
                        walkdownState.map((walkdownuser, index) => (
                          <TableRow
                            align="center"
                            sx={{
                              fontSize: "0.8rem", // Adjust font size
                              color: "rgba(96, 96, 96)",
                              "& > *": {
                                paddingLeft: "15px", // Adjust the padding value as needed
                              },
                            }}
                            key={walkdownuser.userPk}
                            hover={true}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{walkdownuser.firstName}</TableCell>
                            <TableCell>{walkdownuser.lastName}</TableCell>
                            <TableCell>{walkdownuser.email}</TableCell>
                            <TableCell>********</TableCell>
                            <TableCell>
                              {editMode ? (
                                <Checkbox
                                  checked={walkdownuser.isAdmin}
                                  onChange={(e) =>
                                    handleUpdateUser(
                                      {
                                        ...walkdownuser,
                                        isAdmin: e.target.checked,
                                      },
                                      walkdownuser.userPK
                                    )
                                  }
                                />
                              ) : (
                                walkdownuser.isAdmin.toString()
                              )}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Delete">
                                <span>
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteUser(walkdownuser.userPk)
                                    }
                                    disabled={
                                      isUserRoleLoading ||
                                      (!isAdmin && !isWalkdownCoordinator)
                                    }
                                    aria-label="DeleteIcon"
                                    sx={{ color: "red" }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip title="Edit">
                                <span>
                                  <IconButton
                                    onClick={() => handleEditUser(walkdownuser)}
                                    color="primary"
                                    disabled={
                                      editMode ||
                                      isUserRoleLoading ||
                                      (!isAdmin && !isWalkdownCoordinator)
                                    }
                                    aria-label="EditIcon"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Paper>
          </Grid>
          {editMode && (
            <Box sx={{ marginTop: 2, marginBottom: 5 }}>
              <Typography variant="h6" component="div" marginBottom="20px">
                {TextMode ? "Add New User" : "Update User"}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="First Name"
                    value={newUser.firstName}
                    onChange={(e) => {
                      // Perform validation
                      if (e.target.value.trim() === "") {
                        setFirstNameError("First name is required");
                      } else {
                        setFirstNameError("");
                      }
                      setNewUser({ ...newUser, firstName: e.target.value });
                    }}
                    fullWidth
                    error={!!firstNameError} // Set error state based on firstNameError value
                    helperText={firstNameError} // Display error message
                    required // Mark the field as required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Last Name"
                    value={newUser.lastName}
                    onChange={(e) => {
                      // Perform validation
                      if (e.target.value.trim() === "") {
                        setLastNameError("Last name is required");
                      } else {
                        setLastNameError("");
                      }
                      setNewUser({ ...newUser, lastName: e.target.value });
                    }}
                    fullWidth
                    error={!!lastNameError} // Set error state based on lastNameError value
                    helperText={lastNameError} // Display error message
                    required // Mark the field as required
                  />
                </Grid>
                <input type="hidden" value={newUser.userPk} />
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Email"
                    value={newUser.email}
                    onChange={(e) => {
                      // Perform email validation
                      if (!validateEmail(e.target.value)) {
                        setEmailError("Invalid email address");
                      } else {
                        setEmailError("");
                      }
                      setNewUser({ ...newUser, email: e.target.value });
                    }}
                    fullWidth
                    error={!!emailError} // Set error state based on emailError value
                    helperText={emailError} // Display email error message
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Password"
                    value={newUser.password}
                    type="password"
                    onChange={(e) =>
                      setNewUser({ ...newUser, password: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Checkbox
                    checked={newUser.isAdmin}
                    onChange={(e) =>
                      setNewUser({ ...newUser, isAdmin: e.target.checked })
                    }
                  />
                  Is Admin
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSaveUser}
                  disabled={isLoading}
                >
                  {TextMode ? "Save User" : "Update User"}
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCancelEdit}
                  disabled={isLoading}
                  sx={{ marginLeft: 1 }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};
