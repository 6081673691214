import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const Eportal = () => {
  const [loading, setLoading] = useState(true);
  const [injecting, setInjected] = useState(false);
  const search = useParams();
  let id = new URLSearchParams(search).get("id");

  useEffect(() => {
    if (injecting) return;
    if (!loading) {
      let iframe = (document.getElementById("eportalfrm") as any);
    }
  }, [loading])

  return (
    <>
      {loading ? <CircularProgress /> : null}
      <iframe
        title="Eportal"
        id="eportalfrm"
        src={"https://eportal.tengizchevroil.com/#/item/" + id}
        width="100%"
        height="700"
        onLoad={() => setLoading(false)}
        frameBorder="0"
      />
    </>
  );
};